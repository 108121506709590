import {
    GET_ALL_APPLICATIONS,
    GET_ALL_APPLICATIONS_FULFILLED,
    GET_ALL_APPLICATIONS_REJECTED
} from '../constants/Action'

import {getApplicationResourceGRPCClient} from './constants';

const {GetAllApplicationDetailsRequest} = require('../proto/starlord/messages/request/get_all_applications_request_pb')

const AllApplicationsService = getApplicationResourceGRPCClient();


export function GetAllApplicationsAction(payload){

    var request = new GetAllApplicationDetailsRequest()
    request.setTenantid(payload.tenantId)
    request.setPagenum(payload.pageNum)
    request.setPagesize(payload.pageSize)

    return dispatch => {
        dispatch({type: GET_ALL_APPLICATIONS})
        AllApplicationsService.getAllApplications(request, {'x-txn-id': sessionStorage.getItem("xTxnId")}, (err, response) => {
            if(err){
                dispatch({
                    type: GET_ALL_APPLICATIONS_REJECTED,
                    payload: err
                })
            }
            else{
                dispatch({
                    type: GET_ALL_APPLICATIONS_FULFILLED,
                    payload: response.toObject()
                })
            }
        })
    }
}