import React, { useState } from 'react';
import LoginContainer from '../Layout/LoginContainer'
import {OTHER_ERROR } from '../../constants/Error';
import { useHistory } from 'react-router-dom';
import LoginButton from "../Layout/LoginButton";
import {Button} from "@material-ui/core";

export default function DynamicContent(props) {
    const [state, setState] = useState({
        errorState: 0,
        errorMessage: ""
      });

    const history = useHistory();

    const {content} = history.location.state.additionalInfo;

    const onSubmit = (event)=>{
        event.preventDefault();
        props.handlePolicySubmit({
        })
    }

    return (
        <LoginContainer
            title={props.title}
            subtitle={props.subtitle}
            action="Submit"
            loading={props.loading}
            errorState={props.errorState && props.errorType === OTHER_ERROR}
            errorMessage={props.errorMessage}
            errorCode={props.errorCode}
            >

            <div dangerouslySetInnerHTML={{__html: content}}/>

            <LoginButton type="button" onClick={onSubmit}>I Know</LoginButton>
        </LoginContainer>
    )
}