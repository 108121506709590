export const SET_EMAIL = 'SET_EMAIL'
export const AUTHENTICATE = 'AUTHENTICATE'
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS'
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED'
export const RESET_ERROR = 'RESET_ERROR'
export const POLICY_EXECUTION_SUCCESS = 'POLICY_EXECUTION_SUCCESS'
export const RESET_POLICY_EXECUTION_ENDPOINT = 'RESET_POLICY_EXECUTION_ENDPOINT'



export const GET_ALL_APPLICATIONS = 'GET_ALL_APPLICATIONS'
export const GET_ALL_APPLICATIONS_FULFILLED = 'GET_ALL_APPLICATIONS_FULFILLED'
export const GET_ALL_APPLICATIONS_REJECTED = 'GET_ALL_APPLICATIONS_REJECTED'

export const GET_USER_ASSIGNED_APPLICATIONS = 'GET_USER_ASSIGNED_APPLICATIONS'
export const GET_USER_ASSIGNED_APPLICATIONS_FULFILLED = 'GET_USER_ASSIGNED_APPLICATIONS_FULFILLED'
export const GET_USER_ASSIGNED_APPLICATIONS_REJECTED = 'GET_USER_ASSIGNED_APPLICATIONS_REJECTED'

export const GET_COMPLETE_USER_DETAILS = 'GET_COMPLETE_USER_DETAILS'
export const GET_COMPLETE_USER_DETAILS_REJECTED = 'GET_COMPLETE_USER_DETAILS_REJECTED'
export const GET_COMPLETE_USER_DETAILS_FULFILLED = 'GET_COMPLETE_USER_DETAILS_FULFILLED'

export const SET_DIRECTORY = 'SET_DIRECTORY'

export const GET_ALL_DIRECTORIES = 'GET_ALL_DIRECTORIES'
export const GET_ALL_DIRECTORIES_FULFILLED = 'GET_ALL_DIRECTORIES_FULFILLED'
export const GET_ALL_DIRECTORIES_REJECTED = 'GET_ALL_DIRECTORIES_REJECTED'

export const GET_MFA_ENROLL_STATUS = 'GET_MFA_ENROLL_STATUS'
export const GET_MFA_ENROLL_STATUS_FULFILLED = 'GET_MFA_ENROLL_STATUS_FULFILLED'
export const GET_MFA_ENROLL_STATUS_REJECTED = 'GET_MFA_ENROLL_STATUS_REJECTED'

export const START_MFA_ENROLL = 'START_MFA_ENROLL'
export const START_MFA_ENROLL_FULFILLED = 'START_MFA_ENROLL_FULFILLED'
export const START_MFA_ENROLL_REJECTED = 'START_MFA_ENROLL_REJECTED'
