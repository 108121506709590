import React, { useState } from 'react';
import {InputAdornment,IconButton, TextField} from '@material-ui/core'
import LoginContainer from '../Layout/LoginContainer'
import LoginButton from '../Layout/LoginButton';
import VerifiedEmail from './VerifiedEmail';
import { FORM_ERROR, OTHER_ERROR } from '../../constants/Error';
import { FORGOT_PWD_TOKEN_GEN_URL, FORGOT_PWD_INIT_URL } from '../../constants/Url';
import { AUTHENTICATION_FAILED, RESET_ERROR, AUTHENTICATION_SUCCESS } from '../../constants/Action';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import log from 'loglevel';
import Loader from '../../components/Loader';
import config from '../../config';
import Countdown, { zeroPad } from 'react-countdown';
import { sessionValidate } from '../../action/Auth';
import { getDirectoryDisplayNameFromId, getLoginContainerTitle, retrieveUri} from '../../helpers/utils';
import { rsaEncrypt } from '../../helpers/encryption';
import { PWD_RSA_PUB } from '../../constants/Text';

const getExpiryTime = () => {
    return  1 * 60000;
}

export default function PasswordVerification(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [buttonLoading, setButtonLoading] = useState(false);
    const [mailSent, setMailSent] = useState(false);
    const [timerActive, setTimerActive] = useState(false);
    const [timer, setTimer] = useState(Date.now() + getExpiryTime());
    const authState = useSelector(state => state.auth)
    const directoryName = getDirectoryDisplayNameFromId(authState.directoryId)

    log.debug("password verfication mount Props", props);

    const [state, setState] = useState({
        password: '',
        showPassword: 0
      });

    const ShowPassword = (props)=>{
        return (
            <InputAdornment position="end">
                <IconButton
                    edge="end"
                    onClick={() => {handleClickShowPassword(props.value)}}>
                    {state.showPassword === props.value? <img src='/icons/hide.svg' /> : <img src='/icons/show.svg' />}
                </IconButton>
            </InputAdornment>
        )
    }

    const handleClickShowPassword = (value) =>{
        setState({
            ...state,
            showPassword: state.showPassword===value?0:value
        })
    }

    const onInputChange = (event)=>{
        setState({
            ...state,
            password: event.target.value
        })
    }

    const onSubmit = (event)=>{
        event.preventDefault();
        props.handlePolicySubmit({
            password : rsaEncrypt(state.password, PWD_RSA_PUB)
        })
    }

    const forgotPwdInit1 = (event)=> {
        event.preventDefault();
        dispatch({ type: RESET_ERROR })
        setButtonLoading(true);
        
        Axios.get(`${FORGOT_PWD_INIT_URL}?method=1`, {}).then((response)=>{
            if(response.status === 200){
                if(response.data.is_logged_in){
                    dispatch({ type: AUTHENTICATION_SUCCESS })
                    // dispatch({ type: RESET_POLICY_EXECUTION_ENDPOINT })
                    sessionStorage.setItem("login_id", response.headers["x-login-id"])
                    sessionStorage.setItem("internal_id", response.headers["x-internal-id"])
                    setButtonLoading(false);
                }
                else if(!response.data.is_logged_in){
                    // dispatch({ type: POLICY_EXECUTION_SUCCESS, payload: response.data})
                    history.push(retrieveUri(response.data.policy_page_url), {additionalInfo: response.data.additional_info});
                }
            }
        }).catch((error)=>{
            setButtonLoading(false);
            log.debug("Forgot pwd token gen error...", error);
            dispatch({ type: AUTHENTICATION_FAILED, payload: error })
            history.push(config.pageUri.error, {status: error.response.status, statusText: error.response.statusText, headers: error.response.headers, errorData : error.response.data})
        })
    }

    const forgotPwdInit = (event)=> {
        event.preventDefault();
        dispatch({ type: RESET_ERROR })
        setButtonLoading(true);

        sessionValidate((response)=> {
            console.log("response", response);
            sessionStorage.setItem("login_id", response.data.policyPipelineSession.loginId)
            sessionStorage.setItem("internal_id", response.data.policyPipelineSession.olaId)
            sessionStorage.setItem("email", response.data.policyPipelineSession.additionalContext.mail)
            // setValidating(false)
          }, (error)=> {
            // setValidating(false)
            history.push(config.pageUri.error, {status: error.response.status, statusText: error.response.statusText, headers: error.response.headers, errorData : error.response.data})
          })
        Axios.post(FORGOT_PWD_TOKEN_GEN_URL, {}).then((response)=>{
            if (response.status === 200) {
                setMailSent(true);
                setTimer(Date.now() + getExpiryTime());
                setTimerActive(true);
                setButtonLoading(false);
            }
        }).catch((error)=>{
            log.debug("Forgot pwd token gen error status...", error.response.status);
            if(error.response.status == 403){
                log.debug("Forgot pwd token gen error data...", error.response.data);
                forgotPwdInit1(event);
            } else {
                setButtonLoading(false);
                dispatch({ type: AUTHENTICATION_FAILED, payload: error })
                history.push(config.pageUri.error, {status: error.response.status, statusText: error.response.statusText, headers: error.response.headers, errorData : error.response.data})
            }
        })
    }

    const countDownRenderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            setTimerActive(false);
            return (
                <span>
                    Expired!!
                </span>
            );
        } else {
            // Render a countdown
            return (
                <span>
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            );
        }
    };

    const displayForgotPasswordHelperText = () => {
        return (
                <div style={{ fontSize: '0.75rem', marginTop: '1.1rem', fontWeight: 600, color: 'rgba(0, 0, 0, 0.54)' }}>
                    {buttonLoading?<Loader width={30} height={30}/>:timerActive? 
                    <span>
                        You've recieved password reset link in {sessionStorage.getItem("email")} <br/>
                        <a>Didn't recieve? Resend link</a> will be enabled in : <Countdown date={timer} renderer={countDownRenderer} />
                    </span>
                    :
                    <>
                        {mailSent ? <><span>You've recieved password reset link in {sessionStorage.getItem("email")}</span><br/></>: <></>}
                        {!buttonLoading ?
                            <span style={{fontWeight:'bold'}}>
                                <a href="#" onClick={(e) => forgotPwdInit(e)}>{mailSent?"Get password reset link again":"Forgot Password?"}</a>
                            </span>
                            :
                            <Loader width={30} height={30}/>
                        
                        }
                    </>}
                </div>
        );
    };

    return (
        <div className="login-sm-container">
            <LoginContainer title={getLoginContainerTitle("Sign in with credentials", authState.directoryId)}
                onSubmit={onSubmit} loading={props.loading} action="Sign In" errorState={props.errorState && props.errorType === OTHER_ERROR}
                errorMessage={props.errorMessage} errorCode={props.errorCode} errorStatusCode={props.errorStatusCode}>
                    <VerifiedEmail
                        value={props.email}
                    />
                    <TextField
                        icon="key"
                        label="Password"
                        value = {state.password}
                        required
                        autoComplete = "current-password"
                        onChange = {onInputChange}
                        type={state.showPassword ? 'text':'password'}
                        error = {props.errorState && props.errorType === FORM_ERROR}
                        helperText = {props.errorState && props.errorType === FORM_ERROR?props.errorMessage:""}
                        inputRef={input => input && input.focus()}
                        InputProps={{
                            endAdornment: (
                                <ShowPassword value={1}/>
                            )
                            }}
                        disabled={props.loading}
                    />
                    {displayForgotPasswordHelperText()}
                    <LoginButton
                        marginTop= '1.1rem'
                        onSubmit={onSubmit}
                        loading={props.loading}>Sign In</LoginButton>
            </LoginContainer>
        </div>
    )
}