import {
    GET_USER_ASSIGNED_APPLICATIONS,
    GET_USER_ASSIGNED_APPLICATIONS_FULFILLED,
    GET_USER_ASSIGNED_APPLICATIONS_REJECTED,

    GET_COMPLETE_USER_DETAILS,
    GET_COMPLETE_USER_DETAILS_REJECTED,
    GET_COMPLETE_USER_DETAILS_FULFILLED,
    GET_MFA_ENROLL_STATUS,
    GET_MFA_ENROLL_STATUS_FULFILLED,
    GET_MFA_ENROLL_STATUS_REJECTED,
    START_MFA_ENROLL,
    START_MFA_ENROLL_FULFILLED,
    START_MFA_ENROLL_REJECTED
} from '../constants/Action'


const initialState = {
    apps: [],
    details: {},
    profileName: "",
    loadingApps: false,
    errorApps: null,
    loading: false,
    enrolling: false,
    error: null
}

export default (state = initialState, action)=>{
    switch(action.type){
        case GET_USER_ASSIGNED_APPLICATIONS:
            return {
                ...state,
                loadingApps: true,
                errorApps: null
            }
        
        case GET_USER_ASSIGNED_APPLICATIONS_FULFILLED:
            return {
                ...state,
                loadingApps: false,
                apps: action.payload.applicationdetailsList,
                errorApps: null,
            }
        
        case GET_USER_ASSIGNED_APPLICATIONS_REJECTED:
            return {
                ...state,
                loadingApps: false,
                errorApps: action.payload,
            }

        case GET_COMPLETE_USER_DETAILS:
            return {
                ...state,
                details: {},
                loading: true,
                error: null
            }
        
        case GET_COMPLETE_USER_DETAILS_FULFILLED:
            return {
                ...state,
                loading: false,
                details: action.payload,
                profileName: action.payload.firstname + " " + action.payload.lastname,
                error: null,
            }
        case GET_COMPLETE_USER_DETAILS_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload,
                profileName: ""
            }

        case GET_MFA_ENROLL_STATUS:
            return {
                ...state,
                mfaEnrolled: 3,
                enrolling: false
            }
        
        case GET_MFA_ENROLL_STATUS_FULFILLED:
            return {
                ...state,
                mfaEnrolled: action.payload,
                enrolling: false
            }
        case GET_MFA_ENROLL_STATUS_REJECTED:
            return {
                ...state,
                mfaEnrolled: action.payload,
                enrolling: false
            }
        case START_MFA_ENROLL:
            return {
                ...state,
                enrolling: true
            }
        
        case START_MFA_ENROLL_FULFILLED:
            return {
                ...state,
                enrolling: false
            }
        case START_MFA_ENROLL_REJECTED:
            return {
                ...state,
                mfaEnrolled: -1,
                mfaError : action.payload,
                enrolling: false
            }
    }
    return state
} 
