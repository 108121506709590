import React, { useEffect, useState } from "react";

import FormControl from "@material-ui/core/FormControl";
// import Input from "@material-ui/core/Input";
// import Select from "@material-ui/core/Select";
// import ListItemText from "@material-ui/core/ListItemText";
// import MenuItem from "@material-ui/core/MenuItem";
import {Button} from "@material-ui/core";
// import { makeStyles, withStyles } from '@material-ui/core/styles';
import Loader from '../Loader';
import { GetCompleteUserDetails, getMfaEnrolStatus, startSelfMfaEnroll } from "../../action/User";
import { connect } from "react-redux";
import config from "../../config";


// const useStyles = makeStyles((theme) => ({
//   root: {
//     paddingLeft: '40px',
//     paddingRight: '40px',
//     backgroundColor: '#F3F4F5'
//   },
//   toolbar: {
//     marginTop: '30px',
//     marginBottom: '30px',
//   },
//   paper: {
//     boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
//     border: 'none',
//     marginLeft: '10px',
//     marginRight: '10px',
//     padding: '18px'
//   },
//   infoAttribute: {
//     padding: '8px'
//   }
// }));

function MfaEnrollComponent(props)  {



  // const [open, setOpen] = useState(false);
  // const [otpType, setOtpType] = useState('');
  // const otpTypes = [{'value': 'TOTP', 'label': 'Software Token (Google Authenticator)'}, {'value': 'HOTP', 'label': 'Hardware Token'}];
  // const [startEnrolling, setStartEnrolling] = useState(false);

  useEffect(() => {
    if(props.mfaEnrolled === undefined){
      const userId = props.user_email.split('@')[0]
      props.getMfaEnrolStatus(userId,process.env.REACT_APP_TENANT_ID)
    }
  },[])

  const prop = props;
  console.log(props);
  const handleChange = event => {
    // setOtpType(event.target.name);
    window.open(config.mfa.selfEnrollInitUrl);
    // setStartEnrolling(true)
    // props.startSelfMfaEnroll(props.user_email.split('@')[0], window.REACT_APP_TENANT_ID, event.target.value);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

    // const classes = useStyles();


  return (
    <>
      {prop.mfaEnrolled === 0 &&
            <FormControl fullWidth={props.fullWidth}>

              <Button style={{minWidth:'180px'}} id="openMenu" color="primary" onClick={handleChange} variant="contained" disabled={props.enrolling}>
                {props.enrolling?
                  <Loader width={60} height={60}/> : "Proceed For Self Enroll"
                }
              </Button>

              {/* <Select
                value={otpType}
                onChange={handleChange}
                input={<Input id="select-multiple-checkbox" />}
                style={{ display: "none" }}
                open={open}
                onClose={handleClose}
                MenuProps={{
                  anchorEl: document.getElementById("openMenu"),
                  style: { marginTop: 60 }
                }}
              >
                {otpTypes.map(otpTypeItem => (
                  <MenuItem key={otpTypeItem.value} value={otpTypeItem.value}>
                    <ListItemText primary={otpTypeItem.label} />
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>
          }
    </>

  )
}

const mapDispatchToProps = (dispatch) => ({
  getMfaEnrolStatus: (userId, tenantId) => { dispatch(getMfaEnrolStatus(userId, tenantId)) },
  startSelfMfaEnroll: (userId, tenantId, otpType) => { dispatch(startSelfMfaEnroll(userId, tenantId, otpType)) },
  GetCompleteUserDetails: (userId, getMfaEnrolStatusCB) => { dispatch(GetCompleteUserDetails(userId, getMfaEnrolStatusCB)) }
})

const mapStateToProps = (state) => ({
  mfaEnrolled: state.user.mfaEnrolled,
  enrolling: state.user.enrolling || false,
  mfaError: state.user.mfaError,
  samAccountName: state.user.details.samaccountname,
  user_email: state.auth.email
});

export default connect(mapStateToProps, mapDispatchToProps)(MfaEnrollComponent);
