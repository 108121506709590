import React, {useEffect} from 'react'
import { Grid } from "@material-ui/core";
import MyApp from '../Layout/App'
import {useDispatch, useSelector} from 'react-redux'
import MyError from '../Layout/ErrorRpc';
import NoRecords from '../Layout/NoRecords';
import { APP_NOT_FOUND_DESCRIPTION, APP_NOT_FOUND_SEARCH_DESCRIPTION, APP_NOT_FOUND_SEARCH_TITLE, APP_NOT_FOUND_TITLE } from '../../constants/Text';
import config from '../../config';
import OlaLoader from '../Loader';
import { GetUserAssignedApplications } from '../../action/User';


const TYPE = 1

function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const app1 = a.name.toUpperCase();
    const app2 = b.name.toUpperCase();
  
    let comparison = 0;
    if (app1 > app2) {
      comparison = 1;
    } else if (app1 < app2) {
      comparison = -1;
    }
    return comparison;
  } 

export default function MyApps(props){
    const state = useSelector(state => state.user);
    const apps = state.apps
    .filter(app => app.webhomevisibility === true && (config.appVisibility.myApps === "all" || (config.appVisibility.myApps === "on" && app.type === TYPE)) && app.name.toLowerCase().includes(props.query.toLowerCase()))
    .sort(compare)

    const dispatch = useDispatch()

    useEffect(()=> {
        dispatch(GetUserAssignedApplications(sessionStorage.getItem("internal_id")))
      }, [])
    
    if(state.loadingApps)
        return (<OlaLoader/>)
    if(state.errorApps)
        return (<MyError {...state.error}/>)
    if(apps.length === 0 || config.appVisibility.myApps === "off")
        return <NoRecords title={props.query?APP_NOT_FOUND_SEARCH_TITLE:APP_NOT_FOUND_TITLE} description={props.query?APP_NOT_FOUND_SEARCH_DESCRIPTION:APP_NOT_FOUND_DESCRIPTION}/>
    else
        return (
            <Grid container spacing={1}>
                {apps.map((app) => {
                        if (config.homeAppMap.id == app.applicationid) {
                            return  (
                                <Grid item key={app.applicationid}>
                                    <MyApp {...app} category={1} href= {config.homeAppMap.url}/>
                                </Grid>
                            )
                        } else {
                            return  (
                                    <Grid item key={app.applicationid}>
                                        <MyApp {...app} category={1} href= {"/app/" + app.tenantid + "/" + app.applicationid + "/sso/saml"}/>
                                    </Grid>
                                    )
                        }
                    }
                )}
            </Grid>
    )
}
