import JSEncrypt from 'jsencrypt';
import log from 'loglevel';

export function rsaEncrypt(message, publicKey) {

    let RSAEncrypt = new JSEncrypt();
    RSAEncrypt.setPublicKey(publicKey);
    let encryptedPass = RSAEncrypt.encrypt(message);

    log.debug("Encrypted...", encryptedPass);
    return encryptedPass
}