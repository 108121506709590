// source: starlord/messages/models/application_details.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var starlord_messages_models_saml_app_authn_attributes_pb = require('../../../starlord/messages/models/saml_app_authn_attributes_pb.js');
goog.object.extend(proto, starlord_messages_models_saml_app_authn_attributes_pb);
var starlord_messages_models_oauth_application_authn_attributes_pb = require('../../../starlord/messages/models/oauth_application_authn_attributes_pb.js');
goog.object.extend(proto, starlord_messages_models_oauth_application_authn_attributes_pb);
var starlord_messages_models_authentication_protocol_enum_pb = require('../../../starlord/messages/models/authentication_protocol_enum_pb.js');
goog.object.extend(proto, starlord_messages_models_authentication_protocol_enum_pb);
var starlord_messages_models_application_type_enum_pb = require('../../../starlord/messages/models/application_type_enum_pb.js');
goog.object.extend(proto, starlord_messages_models_application_type_enum_pb);
goog.exportSymbol('proto.ApplicationDetails', null, global);
goog.exportSymbol('proto.ApplicationDetails.ApplicationauthnattributesCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ApplicationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ApplicationDetails.repeatedFields_, proto.ApplicationDetails.oneofGroups_);
};
goog.inherits(proto.ApplicationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ApplicationDetails.displayName = 'proto.ApplicationDetails';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ApplicationDetails.repeatedFields_ = [9,10];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ApplicationDetails.oneofGroups_ = [[12,13]];

/**
 * @enum {number}
 */
proto.ApplicationDetails.ApplicationauthnattributesCase = {
  APPLICATIONAUTHNATTRIBUTES_NOT_SET: 0,
  SAMLAPPAUTHNATTRIBUTES: 12,
  OAUTHAPPAUTHNATTRIBUTES: 13
};

/**
 * @return {proto.ApplicationDetails.ApplicationauthnattributesCase}
 */
proto.ApplicationDetails.prototype.getApplicationauthnattributesCase = function() {
  return /** @type {proto.ApplicationDetails.ApplicationauthnattributesCase} */(jspb.Message.computeOneofCase(this, proto.ApplicationDetails.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ApplicationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.ApplicationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ApplicationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ApplicationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    applicationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    protocol: jspb.Message.getFieldWithDefault(msg, 6, 0),
    type: jspb.Message.getFieldWithDefault(msg, 7, 0),
    domain: jspb.Message.getFieldWithDefault(msg, 8, ""),
    externalappassignedmembersList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    externalappassignedgroupsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    isawsapplication: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    samlappauthnattributes: (f = msg.getSamlappauthnattributes()) && starlord_messages_models_saml_app_authn_attributes_pb.SamlAppAuthnAttributes.toObject(includeInstance, f),
    oauthappauthnattributes: (f = msg.getOauthappauthnattributes()) && starlord_messages_models_oauth_application_authn_attributes_pb.OAuthAppAuthnAttributes.toObject(includeInstance, f),
    webhomevisibility: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    logourl: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ApplicationDetails}
 */
proto.ApplicationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ApplicationDetails;
  return proto.ApplicationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ApplicationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ApplicationDetails}
 */
proto.ApplicationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplicationid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {!proto.AuthenticationProtocol} */ (reader.readEnum());
      msg.setProtocol(value);
      break;
    case 7:
      var value = /** @type {!proto.ApplicationType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addExternalappassignedmembers(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addExternalappassignedgroups(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsawsapplication(value);
      break;
    case 12:
      var value = new starlord_messages_models_saml_app_authn_attributes_pb.SamlAppAuthnAttributes;
      reader.readMessage(value,starlord_messages_models_saml_app_authn_attributes_pb.SamlAppAuthnAttributes.deserializeBinaryFromReader);
      msg.setSamlappauthnattributes(value);
      break;
    case 13:
      var value = new starlord_messages_models_oauth_application_authn_attributes_pb.OAuthAppAuthnAttributes;
      reader.readMessage(value,starlord_messages_models_oauth_application_authn_attributes_pb.OAuthAppAuthnAttributes.deserializeBinaryFromReader);
      msg.setOauthappauthnattributes(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWebhomevisibility(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogourl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ApplicationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ApplicationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ApplicationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ApplicationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApplicationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProtocol();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExternalappassignedmembersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getExternalappassignedgroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getIsawsapplication();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getSamlappauthnattributes();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      starlord_messages_models_saml_app_authn_attributes_pb.SamlAppAuthnAttributes.serializeBinaryToWriter
    );
  }
  f = message.getOauthappauthnattributes();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      starlord_messages_models_oauth_application_authn_attributes_pb.OAuthAppAuthnAttributes.serializeBinaryToWriter
    );
  }
  f = message.getWebhomevisibility();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getLogourl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string applicationId = 1;
 * @return {string}
 */
proto.ApplicationDetails.prototype.getApplicationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.setApplicationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenantId = 2;
 * @return {string}
 */
proto.ApplicationDetails.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.ApplicationDetails.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string displayName = 4;
 * @return {string}
 */
proto.ApplicationDetails.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.ApplicationDetails.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AuthenticationProtocol protocol = 6;
 * @return {!proto.AuthenticationProtocol}
 */
proto.ApplicationDetails.prototype.getProtocol = function() {
  return /** @type {!proto.AuthenticationProtocol} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.AuthenticationProtocol} value
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.setProtocol = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ApplicationType type = 7;
 * @return {!proto.ApplicationType}
 */
proto.ApplicationDetails.prototype.getType = function() {
  return /** @type {!proto.ApplicationType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.ApplicationType} value
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string domain = 8;
 * @return {string}
 */
proto.ApplicationDetails.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string externalAppAssignedMembers = 9;
 * @return {!Array<string>}
 */
proto.ApplicationDetails.prototype.getExternalappassignedmembersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.setExternalappassignedmembersList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.addExternalappassignedmembers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.clearExternalappassignedmembersList = function() {
  return this.setExternalappassignedmembersList([]);
};


/**
 * repeated string externalAppAssignedGroups = 10;
 * @return {!Array<string>}
 */
proto.ApplicationDetails.prototype.getExternalappassignedgroupsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.setExternalappassignedgroupsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.addExternalappassignedgroups = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.clearExternalappassignedgroupsList = function() {
  return this.setExternalappassignedgroupsList([]);
};


/**
 * optional bool isAwsApplication = 11;
 * @return {boolean}
 */
proto.ApplicationDetails.prototype.getIsawsapplication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.setIsawsapplication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional SamlAppAuthnAttributes samlAppAuthnAttributes = 12;
 * @return {?proto.SamlAppAuthnAttributes}
 */
proto.ApplicationDetails.prototype.getSamlappauthnattributes = function() {
  return /** @type{?proto.SamlAppAuthnAttributes} */ (
    jspb.Message.getWrapperField(this, starlord_messages_models_saml_app_authn_attributes_pb.SamlAppAuthnAttributes, 12));
};


/**
 * @param {?proto.SamlAppAuthnAttributes|undefined} value
 * @return {!proto.ApplicationDetails} returns this
*/
proto.ApplicationDetails.prototype.setSamlappauthnattributes = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.ApplicationDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.clearSamlappauthnattributes = function() {
  return this.setSamlappauthnattributes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ApplicationDetails.prototype.hasSamlappauthnattributes = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional OAuthAppAuthnAttributes oauthAppAuthnAttributes = 13;
 * @return {?proto.OAuthAppAuthnAttributes}
 */
proto.ApplicationDetails.prototype.getOauthappauthnattributes = function() {
  return /** @type{?proto.OAuthAppAuthnAttributes} */ (
    jspb.Message.getWrapperField(this, starlord_messages_models_oauth_application_authn_attributes_pb.OAuthAppAuthnAttributes, 13));
};


/**
 * @param {?proto.OAuthAppAuthnAttributes|undefined} value
 * @return {!proto.ApplicationDetails} returns this
*/
proto.ApplicationDetails.prototype.setOauthappauthnattributes = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.ApplicationDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.clearOauthappauthnattributes = function() {
  return this.setOauthappauthnattributes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ApplicationDetails.prototype.hasOauthappauthnattributes = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool webHomeVisibility = 14;
 * @return {boolean}
 */
proto.ApplicationDetails.prototype.getWebhomevisibility = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.setWebhomevisibility = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string logoUrl = 15;
 * @return {string}
 */
proto.ApplicationDetails.prototype.getLogourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.ApplicationDetails} returns this
 */
proto.ApplicationDetails.prototype.setLogourl = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


goog.object.extend(exports, proto);
