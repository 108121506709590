import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import config from '../config'
import axios from 'axios'

class index extends Component {
    constructor(props){
        super(props)
        this.state = {
            appName : new URLSearchParams(window.location.search).get('app_name'),
            returnPage : new URLSearchParams(window.location.search).get('return_page')
        }
    }

    componentDidMount(){
        let endpoint = `${config.authzInitUrl}/auth?app_name=${this.state.appName}`
        if(this.state.returnPage)
            endpoint += `&return_page=${this.state.returnPage}`

        axios.get(endpoint)
            .then((res)=>{
                this.props.history.push(config.pageUri.verifyUser,
                {
                    data : res.data
                })
            })
    }
    render() {
        return (
            <React.Fragment></React.Fragment>
        )
    }
}

export default withRouter(index)