import React, { Component } from 'react'
import NotFound from '../NotFound'

export default class index extends Component {

    constructor(props){
        super(props)
        this.state = {
            error : false
        }
    }

    componentDidCatch(error, info){
        this.setState({error : true})
    }
    render() {
        if(this.state.error){
        return (
                <NotFound message = "Some error occurred"/>
            )
        }
        else{
            return this.props.children
        }
    }
}
