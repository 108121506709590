import {
    GET_ALL_APPLICATIONS,
    GET_ALL_APPLICATIONS_FULFILLED,
    GET_ALL_APPLICATIONS_REJECTED
} from '../constants/Action'


const initialState = {
    apps: [],
    loading: false,
    error: null
}


export default (state = initialState, action)=>{
    switch(action.type){
        case GET_ALL_APPLICATIONS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case GET_ALL_APPLICATIONS_FULFILLED:
            return {
                ...state,
                loading: false,
                apps: action.payload.applicationdetailsList,
                error: null,
            }
        case GET_ALL_APPLICATIONS_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        
    }
    return state
} 
