/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var starlord_messages_response_application_response_pb = require('../../starlord/messages/response/application_response_pb.js')

var starlord_messages_response_multi_application_response_pb = require('../../starlord/messages/response/multi_application_response_pb.js')

var starlord_messages_response_application_assignment_response_pb = require('../../starlord/messages/response/application_assignment_response_pb.js')

var starlord_messages_response_generic_response_pb = require('../../starlord/messages/response/generic_response_pb.js')

var starlord_messages_request_get_application_request_pb = require('../../starlord/messages/request/get_application_request_pb.js')

var starlord_messages_request_application_assignment_unassignment_request_pb = require('../../starlord/messages/request/application_assignment_unassignment_request_pb.js')

var starlord_messages_request_get_application_assignment_request_pb = require('../../starlord/messages/request/get_application_assignment_request_pb.js')

var starlord_messages_request_get_all_applications_request_pb = require('../../starlord/messages/request/get_all_applications_request_pb.js')

var starlord_messages_request_application_upsert_request_pb = require('../../starlord/messages/request/application_upsert_request_pb.js')

var starlord_messages_request_application_delete_request_pb = require('../../starlord/messages/request/application_delete_request_pb.js')
const proto = require('./application_resource_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.ApplicationControllerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.ApplicationControllerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.ApplicationUpsertRequest,
 *   !proto.ApplicationResponse>}
 */
const methodInfo_ApplicationController_createApplication = new grpc.web.AbstractClientBase.MethodInfo(
  starlord_messages_response_application_response_pb.ApplicationResponse,
  /** @param {!proto.ApplicationUpsertRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  starlord_messages_response_application_response_pb.ApplicationResponse.deserializeBinary
);


/**
 * @param {!proto.ApplicationUpsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.ApplicationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApplicationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ApplicationControllerClient.prototype.createApplication =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ApplicationController/createApplication',
      request,
      metadata || {},
      methodInfo_ApplicationController_createApplication,
      callback);
};


/**
 * @param {!proto.ApplicationUpsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApplicationResponse>}
 *     A native promise that resolves to the response
 */
proto.ApplicationControllerPromiseClient.prototype.createApplication =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ApplicationController/createApplication',
      request,
      metadata || {},
      methodInfo_ApplicationController_createApplication);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.ApplicationUpsertRequest,
 *   !proto.ApplicationResponse>}
 */
const methodInfo_ApplicationController_updateApplication = new grpc.web.AbstractClientBase.MethodInfo(
  starlord_messages_response_application_response_pb.ApplicationResponse,
  /** @param {!proto.ApplicationUpsertRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  starlord_messages_response_application_response_pb.ApplicationResponse.deserializeBinary
);


/**
 * @param {!proto.ApplicationUpsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.ApplicationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApplicationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ApplicationControllerClient.prototype.updateApplication =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ApplicationController/updateApplication',
      request,
      metadata || {},
      methodInfo_ApplicationController_updateApplication,
      callback);
};


/**
 * @param {!proto.ApplicationUpsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApplicationResponse>}
 *     A native promise that resolves to the response
 */
proto.ApplicationControllerPromiseClient.prototype.updateApplication =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ApplicationController/updateApplication',
      request,
      metadata || {},
      methodInfo_ApplicationController_updateApplication);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.ApplicationDeleteRequest,
 *   !proto.ApplicationResponse>}
 */
const methodInfo_ApplicationController_deleteApplication = new grpc.web.AbstractClientBase.MethodInfo(
  starlord_messages_response_application_response_pb.ApplicationResponse,
  /** @param {!proto.ApplicationDeleteRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  starlord_messages_response_application_response_pb.ApplicationResponse.deserializeBinary
);


/**
 * @param {!proto.ApplicationDeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.ApplicationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApplicationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ApplicationControllerClient.prototype.deleteApplication =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ApplicationController/deleteApplication',
      request,
      metadata || {},
      methodInfo_ApplicationController_deleteApplication,
      callback);
};


/**
 * @param {!proto.ApplicationDeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApplicationResponse>}
 *     A native promise that resolves to the response
 */
proto.ApplicationControllerPromiseClient.prototype.deleteApplication =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ApplicationController/deleteApplication',
      request,
      metadata || {},
      methodInfo_ApplicationController_deleteApplication);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetApplicationDetailsRequest,
 *   !proto.ApplicationResponse>}
 */
const methodInfo_ApplicationController_getApplications = new grpc.web.AbstractClientBase.MethodInfo(
  starlord_messages_response_application_response_pb.ApplicationResponse,
  /** @param {!proto.GetApplicationDetailsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  starlord_messages_response_application_response_pb.ApplicationResponse.deserializeBinary
);


/**
 * @param {!proto.GetApplicationDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.ApplicationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApplicationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ApplicationControllerClient.prototype.getApplications =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ApplicationController/getApplications',
      request,
      metadata || {},
      methodInfo_ApplicationController_getApplications,
      callback);
};


/**
 * @param {!proto.GetApplicationDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApplicationResponse>}
 *     A native promise that resolves to the response
 */
proto.ApplicationControllerPromiseClient.prototype.getApplications =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ApplicationController/getApplications',
      request,
      metadata || {},
      methodInfo_ApplicationController_getApplications);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetAllApplicationDetailsRequest,
 *   !proto.MultiApplicationResponse>}
 */
const methodInfo_ApplicationController_getAllApplications = new grpc.web.AbstractClientBase.MethodInfo(
  starlord_messages_response_multi_application_response_pb.MultiApplicationResponse,
  /** @param {!proto.GetAllApplicationDetailsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  starlord_messages_response_multi_application_response_pb.MultiApplicationResponse.deserializeBinary
);


/**
 * @param {!proto.GetAllApplicationDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.MultiApplicationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.MultiApplicationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ApplicationControllerClient.prototype.getAllApplications =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ApplicationController/getAllApplications',
      request,
      metadata || {},
      methodInfo_ApplicationController_getAllApplications,
      callback);
};


/**
 * @param {!proto.GetAllApplicationDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.MultiApplicationResponse>}
 *     A native promise that resolves to the response
 */
proto.ApplicationControllerPromiseClient.prototype.getAllApplications =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ApplicationController/getAllApplications',
      request,
      metadata || {},
      methodInfo_ApplicationController_getAllApplications);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.ApplicationAssignmentOrUnassignmentRequest,
 *   !proto.GenericResponse>}
 */
const methodInfo_ApplicationController_assignApplication = new grpc.web.AbstractClientBase.MethodInfo(
  starlord_messages_response_generic_response_pb.GenericResponse,
  /** @param {!proto.ApplicationAssignmentOrUnassignmentRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  starlord_messages_response_generic_response_pb.GenericResponse.deserializeBinary
);


/**
 * @param {!proto.ApplicationAssignmentOrUnassignmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GenericResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ApplicationControllerClient.prototype.assignApplication =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ApplicationController/assignApplication',
      request,
      metadata || {},
      methodInfo_ApplicationController_assignApplication,
      callback);
};


/**
 * @param {!proto.ApplicationAssignmentOrUnassignmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericResponse>}
 *     A native promise that resolves to the response
 */
proto.ApplicationControllerPromiseClient.prototype.assignApplication =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ApplicationController/assignApplication',
      request,
      metadata || {},
      methodInfo_ApplicationController_assignApplication);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.ApplicationAssignmentOrUnassignmentRequest,
 *   !proto.GenericResponse>}
 */
const methodInfo_ApplicationController_unassignApplication = new grpc.web.AbstractClientBase.MethodInfo(
  starlord_messages_response_generic_response_pb.GenericResponse,
  /** @param {!proto.ApplicationAssignmentOrUnassignmentRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  starlord_messages_response_generic_response_pb.GenericResponse.deserializeBinary
);


/**
 * @param {!proto.ApplicationAssignmentOrUnassignmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GenericResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ApplicationControllerClient.prototype.unassignApplication =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ApplicationController/unassignApplication',
      request,
      metadata || {},
      methodInfo_ApplicationController_unassignApplication,
      callback);
};


/**
 * @param {!proto.ApplicationAssignmentOrUnassignmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericResponse>}
 *     A native promise that resolves to the response
 */
proto.ApplicationControllerPromiseClient.prototype.unassignApplication =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ApplicationController/unassignApplication',
      request,
      metadata || {},
      methodInfo_ApplicationController_unassignApplication);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetApplicationAssignmentRequest,
 *   !proto.ApplicationAssignmentResponse>}
 */
const methodInfo_ApplicationController_getApplicationAssignments = new grpc.web.AbstractClientBase.MethodInfo(
  starlord_messages_response_application_assignment_response_pb.ApplicationAssignmentResponse,
  /** @param {!proto.GetApplicationAssignmentRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  starlord_messages_response_application_assignment_response_pb.ApplicationAssignmentResponse.deserializeBinary
);


/**
 * @param {!proto.GetApplicationAssignmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.ApplicationAssignmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ApplicationAssignmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ApplicationControllerClient.prototype.getApplicationAssignments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ApplicationController/getApplicationAssignments',
      request,
      metadata || {},
      methodInfo_ApplicationController_getApplicationAssignments,
      callback);
};


/**
 * @param {!proto.GetApplicationAssignmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ApplicationAssignmentResponse>}
 *     A native promise that resolves to the response
 */
proto.ApplicationControllerPromiseClient.prototype.getApplicationAssignments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ApplicationController/getApplicationAssignments',
      request,
      metadata || {},
      methodInfo_ApplicationController_getApplicationAssignments);
};


module.exports = proto;

