import React from 'react'
import '../../../../stylesheet/error.css'
import { Button } from '@material-ui/core'

export default function NotFound(props) {
    return (
        <div id="notfound">
            <div id="notfound">
                <div className="notfound">
                    <img id="error-icon" src="/icons/error.svg" style={{marginBottom: "20px"}}/>
                    <h2>Error 404</h2>
                    <p>Oops! Page not found</p>
                    <p>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>
                    <br/>
                    <Button href="/home" variant="contained" color="primary" style={{marginBottom: '12px'}}>Go to homepage</Button>
                </div>
            </div>
        </div>
    )
}