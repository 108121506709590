import React, { useEffect, useState } from "react";
import MyApps from './myApps'
import OtherApps from './otherApps'
import { GetCompleteUserDetails } from '../../action/User'
import {useDispatch} from 'react-redux'
import NavbarPage from "../NavBar";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { InputBase, Toolbar, Grid } from "@material-ui/core";
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import SearchIcon from '@material-ui/icons/Search';

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '80%',
      backgroundColor: '#92C83E',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 0,
    color: '#000',
    fontSize: theme.typography.pxToRem(18),
    marginRight: '40px',
    '&:focus': {
      opacity: 1,
    }
  },
}))((props) => <Tab disableRipple {...props} />);


const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '40px',
    paddingRight: '40px',
    backgroundColor: '#F3F4F5'
  },
  toolbar: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  search: {
    position: 'relative',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: 'white',
    margin: theme.spacing(1)
  },
  searchIcon: {
    padding: theme.spacing(0, 1.5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1.5, 1, 1.5, 1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width')
  }
}));


export default function HomePage(props){
  const [state, setState] = useState({
    searchKey: ""
  })
  const dispatch = useDispatch()
  const [value, setValue] = React.useState("1");

  useEffect(()=> {
    dispatch(GetCompleteUserDetails(sessionStorage.getItem('login_id')))
  }, [])

  const handleSearch = (event) => {
    setState({
        ...state,
        searchKey: event.target.value
        })
  }

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <>
      <NavbarPage/>
      <div className={classes.root}>
      <TabContext value={value}>
          <Toolbar>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.toolbar}
            >
              <Grid >
                <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
                  <StyledTab label="My Apps" value="1"/>
                  <StyledTab label="Other Apps" value="2"/>
                </StyledTabs>
              </Grid>
              <Grid>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onInput={handleSearch}
                  />
                </div>
              </Grid>
            </Grid>
          </Toolbar>
          <TabPanel value="1">
            <MyApps query={state.searchKey}/>
          </TabPanel>
          <TabPanel value="2">
            <OtherApps query={state.searchKey}/>
          </TabPanel>
        </TabContext>
      </div>
    </>
    );
}
