import React, { useState } from 'react';
import {InputAdornment,IconButton, TextField} from '@material-ui/core'
import {Visibility, VisibilityOff} from '@material-ui/icons';
import LoginContainer from '../Layout/LoginContainer'
import LoginButton from '../Layout/LoginButton';
import VerifiedEmail from './VerifiedEmail';
import { FORM_ERROR, OTHER_ERROR } from '../../constants/Error';
import config from '../../config';
import Countdown, { zeroPad } from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux'
import { AUTHENTICATION_FAILED, RESET_ERROR } from '../../constants/Action';
import { AUTHN_TRIGGER_OTP_URL } from '../../constants/Url';
import axios from 'axios';
import Loader from '../Loader';
import { getDirectoryDisplayNameFromId, getLoginContainerTitle } from '../../helpers/utils';

const getExpiryTime = () => {
    return config.resendOTPInMins * 60000;
}

export default function MFASmsOtpVerification(props) {
    const authState = useSelector(state => state.auth)
    const directoryName = getDirectoryDisplayNameFromId(authState.directoryId)
    const [state, setState] = useState({
        otp: '',
        showPassword: 0
    });
    const [timer, setTimer] = useState(Date.now() + getExpiryTime());
    const [buttonLoading, setButtonLoading] = useState(false);
    const [otpExpired, setOtpExpired] = useState(false);
    const dispatch = useDispatch();

    const ShowPassword = (props)=>{
        return (
            <InputAdornment position="end">
                <IconButton
                    edge="end"
                    onClick={() => {handleClickShowPassword(props.value)}}>
                    {state.showPassword === props.value? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        )
    }

    const triggerOTP = (payload) => {
        setButtonLoading(true)
        dispatch({ type: RESET_ERROR })
        axios.post(AUTHN_TRIGGER_OTP_URL, { credentials: payload, sendSms: true, trigger: "cidm_otp_authentication" }).then((response) => {
            setButtonLoading(false)
            console.log("triggerOTP response :", response);
            if (response.status === 200) {
                setTimer(Date.now() + getExpiryTime());
                setOtpExpired(false)
                // dispatch({type: SWITCH_OTP_EXPIRED, payload: false})
            }
        }).catch((error) => {
            setButtonLoading(false)
            console.log(error)
            dispatch({ type: AUTHENTICATION_FAILED, payload: error })
        })
    }

    const handleClickShowPassword = (value) => {
        setState({
            ...state,
            showPassword: state.showPassword===value?0:value
        })
    }

    const onInputChange = (event)=>{
        setState({
            ...state,
            otp: event.target.value
        })
    }

    const onSubmit = (event)=>{
        event.preventDefault();
        props.handlePolicySubmit({
            otp: state.otp
        })
    }

    const countDownRenderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            setOtpExpired(true);
            return (
                <span>
                    OTP Expired!!
                </span>
            );
        } else {
            // Render a countdown
            return (
                <span>
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            );
        }
    };

    const displayPreferedAppHelperText = () => {
        return (
                <p style={{ fontSize: '0.75rem', marginTop: '20px', fontWeight: 600, fontFamily: 'Gentona', color: 'rgba(0, 0, 0, 0.54)' }}>
                {!otpExpired ?
                    <span>
                        <a>Resend OTP</a> will be enabled in : <Countdown date={timer} renderer={countDownRenderer} />
                    </span>
                    :
                    <>
                    {!buttonLoading ?
                        <span style={{fontWeight:'bold'}}>
                            <a href="#" onClick={() => triggerOTP({})}>Resend OTP</a>
                        </span>
                        :
                        <Loader width={30} height={30}/>
                    }
                    </>
                }
                </p>
        );
    };

    return (
        <div className="login-sm-container">
            <LoginContainer title={getLoginContainerTitle("2FA Verification", authState.directoryId)}
                onSubmit={onSubmit} loading={props.loading} action="Submit" errorState={props.errorState && props.errorType === OTHER_ERROR}
                errorCode={props.errorCode}
                errorMessage={props.errorMessage}>
               {props.email && 
                    <VerifiedEmail
                        value={props.email}
                    />
                }
                <TextField
                    id="outlined-full-width"
                    autoComplete='off'
                    inputProps={{
                        autocomplete: 'off',
                    }}
                    label="OTP"
                    required
                    name='nothing'
                    type='password'
                    value={state.otp}
                    onChange={onInputChange}
                    error={props.errorState && props.errorType === FORM_ERROR}
                    helperText={props.errorState && props.errorType === FORM_ERROR ? props.errorMessage : "You must have recieved an SMS with 6 digit OTP."}
                    disabled={props.loading}
                    inputRef={input => input && input.focus()}
                />
                {displayPreferedAppHelperText()}
                <LoginButton marginTop='1px' onSubmit={onSubmit} loading={props.loading}>Submit</LoginButton>
            </LoginContainer>
        </div>
    )
}
