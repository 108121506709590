import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from './loader.json'

class OlaLoader extends Component {


  render(){

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <>
        <Lottie options={defaultOptions}
              height={this.props.height?this.props.height:100}
              width={this.props.width?this.props.width:100}
        />
      </>
    )
  }
}

export default OlaLoader