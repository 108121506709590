import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, InputLabel } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
    "&:hover": {
      backgroundColor: 'transparent'
    }
  },
}));

export default function Troubleshoot(props) {
  const classes = useStyles();
  const [show, setShow] = useState(true);

  const showProp = () => {
      setShow(!show);
  }

  return (
    <div className={classes.root} style={{marginTop: '20px', position: 'absolute'}}>
      <div style={{marginBottom: '10px'}}>
        <b>Troubleshoot : </b>
      </div>
      <div>
          {show && props.body}
      </div>
    </div>
  );
}