import React, {useEffect} from 'react'
import { Grid } from "@material-ui/core";
import {useSelector} from 'react-redux'
import MyApp from '../Layout/App';
import MyError from '../Layout/ErrorRpc';
import { APP_NOT_FOUND_DESCRIPTION, APP_NOT_FOUND_SEARCH_DESCRIPTION, APP_NOT_FOUND_SEARCH_TITLE, APP_NOT_FOUND_TITLE } from '../../constants/Text';
import NoRecords from '../Layout/NoRecords';
import config from '../../config';
import OlaLoader from '../Loader';
import {useDispatch} from 'react-redux';
import { GetAllApplicationsAction } from '../../action/Application';
import { GetUserAssignedApplications } from '../../action/User';

const TYPE = 1

function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const app1 = a.name.toUpperCase();
    const app2 = b.name.toUpperCase();
  
    let comparison = 0;
    if (app1 > app2) {
      comparison = 1;
    } else if (app1 < app2) {
      comparison = -1;
    }
    return comparison;
  }  

export default function OtherApps(props){

    const apps = useSelector(state => state.application.apps
        .filter(app => !state.user.apps.map(aap => (aap.applicationid != app.applicationid)).includes(false)))
        .filter(app => app.webhomevisibility === true && (config.appVisibility.otherApps === "all" || (config.appVisibility.otherApps === "on" && app.type === TYPE)) && app.name.toLowerCase().includes(props.query.toLowerCase()))
        .sort(compare)
    
    const dispatch = useDispatch();
    
    useEffect(()=> {
        if(config.appVisibility.otherApps !== "off"){
            dispatch(GetAllApplicationsAction({tenantId: config.tenantId, pageNum: 1, pageSize: 10}))
            dispatch(GetUserAssignedApplications(sessionStorage.getItem("internal_id"))) 
        }
    }, [])

    const userState = useSelector(state => state.user)
    const appState = useSelector(state => state.application)

    if(userState.loadingApps || appState.loading){
        return (
            <OlaLoader/>
        )
    }
    if(userState.errorApps)
        return (<MyError {...userState.error}/>)
    if(appState.error)
        return (<MyError {...appState.error}/>)
    if(apps.length === 0 || config.appVisibility.otherApps === "off")
        return <NoRecords title={props.query?APP_NOT_FOUND_SEARCH_TITLE:APP_NOT_FOUND_TITLE} description={props.query?APP_NOT_FOUND_SEARCH_DESCRIPTION:APP_NOT_FOUND_DESCRIPTION}/>
    else
        return (
            <Grid container spacing={1} >
                {apps.map(app=> (
                    <Grid item key={app.applicationid}>
                        <MyApp {...app} category={2} />
                    </Grid>
                ))}
            </Grid>
        )
}
