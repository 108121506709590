import React from 'react'
import config from '../../config'
import Troubleshoot from '../Layout/Troubleshoot'
import { Button } from '@material-ui/core'
import '../../../stylesheet/error.css'
import MfaEnrollComponent from '../Settings/mfaenrol'
import MfaNotEnrolled from './mfa_not_enrolled_error'

function MfaEnrollMessage() {
    return (
        <>
            <MfaEnrollComponent/>
            <br/>
            <b>OR</b> 
            <p>
                <br/>Please contact IT support at <b>itsupport@olacabs.com (@OlaDesk)</b> for <b>Multi-Factor Authentication Enrollment</b>
            </p>
        </>
    ); 
}

export default function MyError(props) {
    // if(props.history.action === "POP")
    //     props.history.push(config.pageUri.error, {status: 404, statusText: "Not Found"})

    const {status, statusText,errorData, headers} = props.history.location.state


    if(props.history.action === "POP")
        props.history.push(config.pageUri.error, {status: status, statusText: statusText, errorData:errorData,headers:headers})

    console.log("headers", headers)

    let title = "Oops...! Something went wrong"
    let message = ""
    let heading = "Error "+status;
    let icon = "/icons/error.svg"
    const errorCode = errorData ? errorData.errorCode : null;
    console.log("errorCode ",errorData);
    switch(status){
        case 400:
            if(errorCode === "MFA005"){
                icon = "/icons/authorized.svg"
                heading = "UNAUTHORIZED"
                title = "The application requires MFA enrollment"
                message = <MfaEnrollMessage/>
            }
            break
        case 401:
            title = "Unauthorized"
            if(errorCode === "AUTHN030"){
                title = "Link Expired"
            }
            break
        case 403:
            title = "Permission denied"
            break
        case 404:
            title = "Page not found"
            break
        case 429:
            title = "Retry limit exceeded. Please try after sometime"
            break
    }
    
    return (
        <>
        { errorData.errorCode === "MFA005" ? <MfaNotEnrolled {...props}/> : 
        <div id="notfound">
            <div id="notfound">
                <div className="notfound">
                    <img id="error-icon" src={icon} style={{marginBottom: "20px"}}/>
                    <h2>{heading}</h2>
                    {title}
                    <br/>
                    <br/>
                    {message}
                    <Button href="/home" variant="contained" color="primary" style={{marginBottom: '12px'}}>Go to homepage</Button>
                    {headers && 
                    <Troubleshoot 
                        body={
                            <div>
                            Transaction Id :
                            <p>{headers["x-txn-id"]}</p>
                            Request Id :
                            <p>{headers["x-req-id"]}</p>
                            </div>
                        }
                    />}
                </div>
            </div>
        </div>
                    }
                    </>
    )
}