import React from 'react';
import {Typography} from '@material-ui/core'
import LoginContainer from '../Layout/LoginContainer'
import LoginButton from '../Layout/LoginButton';
import VerifiedEmail from './VerifiedEmail';
import {OTHER_ERROR } from '../../constants/Error';
import config from '../../config';

export default function TermAcceptancePage(props) {

    const onSubmit = (event)=>{
        event.preventDefault();
        props.handlePolicySubmit({
        })
    }

    return (
        <LoginContainer
            title="Term Acceptance" 
            subtitle={props.subtitle}
            onSubmit={onSubmit}
            action="Submit"
            loading={props.loading}
            errorState={props.errorState && props.errorType === OTHER_ERROR}
            errorMessage={props.errorMessage}
            errorCode={props.errorCode}
            >
        {/* <img src = '/images/lms-logo.png' alt= "lms-logo" width="100%"/> */}
        {props.email && 
            <VerifiedEmail
                value={props.email}
            />
        }
        <br/>
        <div className="text-center">
            <Typography variant="body2">{config.termAcceptanceContent}</Typography>
        </div>
        <LoginButton
            marginTop= '1.1rem'
            onSubmit={onSubmit}
            loading={props.loading}>I Agree</LoginButton>
        </LoginContainer>
    )
}