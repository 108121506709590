import config from "../config";
import { directoriesConfig } from "../constants/Form";

export function retrieveUri(fullUrl){
    if(fullUrl.indexOf('//') !== -1)
        fullUrl = fullUrl.substring(fullUrl.indexOf('//') + 2);
    return fullUrl.substring(fullUrl.indexOf('/'));
}

export function isCompleteUri(fullUrl){
    if(fullUrl.indexOf('//') !== -1)
        return true;
    else
        return false;
}

export function retrieveEmail(username){
    if(username.indexOf('@') === -1){
        username += "@" + config.mailDomain
    }
    return username;
}

export function getDirectoryDisplayNameFromId(id){
    let displayname = "";
    directoriesConfig.forEach(d => {
        if(d.id == id)
        displayname= d.displayName;
    })
    return displayname;
}

export function getLoginContainerTitle(pageTitle, directoryId){
    let finalTitle = pageTitle;
    if(config.directory.useDefault == "true")
        return finalTitle;
    else
        return finalTitle + " - " + getDirectoryDisplayNameFromId(directoryId);
}