import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Paper, Divider, Typography} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router';
import config from '../../config'

const useStyles = makeStyles((theme) => ({
    root: {
      // textAlign: 'center'
      backgroundColor: "#F3F4F5"
    },
    paper: {
        maxWidth: "384px",
        margin: `${theme.spacing(1)}px auto`,
    },
    section1: {
      margin: theme.spacing(1, 1),
    },
    section2: {
      margin: "12px 0px",
    },
    section3: {
        // margin: theme.spacing(1)
    },
    alert: {
        marginTop: theme.spacing(2),
        textAlign: "start",
        boxShadow: 'none',
        borderRadius: 0,
        border: 'none'
    },
    divider: {
      marginTop: "20px"
    },
    olaCidmLogo: {
      width: "342px",
      paddingTop: "32px",
      paddingBottom: "32px"
    }
  }));

export default function SimplePaper(props) {
  const classes = useStyles();
  const history = useHistory();

  if(props.errorState && props.errorCode === "MFA005"){
    history.push(config.pageUri.error, {status: props.errorStatusCode, errorData : {errorCode: props.errorCode}})
  }

  return (
    <div className={classes.root}>
      <div className={classes.section1}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          >
            <img src = '/images/logo_olacidm.svg' alt= "ola-logo" className={classes.olaCidmLogo}/>
        </Grid>
      </div>
      <Paper className={classes.paper}>
          <div className={classes.section2}>
              <Typography variant="body1" style={{color: "black", fontWeight: 600}}>
                  {props.title}
              </Typography>
              <Typography variant="overline">
                  {props.subtitle}
              </Typography>
              {props.errorState && <Alert severity="error" className={classes.alert}>{props.errorMessage}. <a href="/home">Login to Homepage</a></Alert>}
          </div>
          <div className={classes.section3}>
              <form onSubmit={props.onSubmit}>
                  {props.children}
              </form>
          </div>
      </Paper>
    </div>
  );
}