import React, { useEffect } from 'react';
import '../stylesheet/style.css'
import {BrowserRouter as Router, Route,Switch, Redirect} from 'react-router-dom'
import UsernameVerification from './components/Login/UsernameVerification'
import PasswordVerification from './components/Login/PasswordVerification'
import ErrorPage from './components/Error'
import HealthCheck from './components/HealthCheck'
import NotFound from './components/Error/NotFound'
import HomePage from './components/HomePage';
import Settings from './components/Settings';
import Config from './config'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
// import 'mdbreact/dist/css/mdb.css';
import ChangePassword from './components/Login/ChangePassword';
import ResetPassword from './components/Login/ResetPassword';
import ForgotPasswordReset from './components/Login/ForgotPasswordReset';
import PolicyRoute from './helpers/PolicyRoute'
import withHomeAuth from './helpers/withHomeAuth'
import ConfirmationPage from './components/Login/ConfirmationPage';
import VerifyRedirect from './helpers/VerifyRedirect';
import ErrorBoundaries from './components/Error/ErrorBoundaries/index'
import AppLogin from './components/Login/AppLogin';
import ForgotPwdInit from './components/Login/ForgotPwdInit';
import MFATotpCodeVerification from './components/Login/MFATotpCodeVerification';
import MFASmsOtpVerification from './components/Login/MFASmsOtpVerification';
import MFARotpCodeVerification from './components/Login/MFARotpCodeVerification';
import MFAHotpCodeVerification from './components/Login/MFAHotpCodeVerification';
import MFAFotpCodeVerification from './components/Login/MFAFotpCodeVerification';
import LMSPrompt from './components/Login/LMSPrompt';
import ManagerAccessReview from './components/Login/ManagerAccessReview.js';
import MfaNotEnrolled from './components/Error/mfa_not_enrolled_error.js'
import MFAEnrollmentEnforce from './components/Login/MFAEnrollmentEnforce';
import './helpers/axiosSetting';
import SmsAndRmOTPVerification from './components/Login/SmsAndRmOTPVerification';
import DynamicContentPage from './components/Login/DynamicContent'
import TermAcceptancePage from './components/Login/TermAcceptancePage';
const SITE_KEY = "6Lc09honAAAAAGs9l5QbKjSoINF5VLUp6v3zYsmw"

export default function App(props){

  // useEffect(()=> {
    

  // }, [])

  return (
    <Router>
      <div className="App" style={{backgroundColor: "#f3f4f5"}}>
      <ErrorBoundaries>
          <Switch>
            <Route path = "/app/:tenantId/:appId/sso/saml" component = {AppLogin}/>
            <Route path = "/app/forgot-pwd/init" component = {ForgotPwdInit}/>
            <Route path = {Config.pageUri.healthcheck} exact component = {HealthCheck}/>
            <PolicyRoute path = {Config.pageUri.smsAndRmOtpCode} exact component = {SmsAndRmOTPVerification}/>
            <PolicyRoute path = {Config.pageUri.acceptTerms}  exact component = {TermAcceptancePage}/>
            <PolicyRoute path = {Config.pageUri.username} exact component = {UsernameVerification}/>
            <PolicyRoute path = {Config.pageUri.password} exact component = {PasswordVerification}/>
            <PolicyRoute path = {Config.pageUri.mfaTotpCode}  exact component = {MFATotpCodeVerification}/>
            <PolicyRoute path = {Config.pageUri.mfaSmsOtp}  exact component = {MFASmsOtpVerification}/>
            <PolicyRoute path = {Config.pageUri.mfaRotpCode}  exact component = {MFARotpCodeVerification}/>
            <PolicyRoute path = {Config.pageUri.mfaHotpCode}  exact component = {MFAHotpCodeVerification}/>
            <PolicyRoute path = {Config.pageUri.mfaFotpCode}  exact component = {MFAFotpCodeVerification}/>
            <PolicyRoute path = {Config.pageUri.forgotPasswordReset} exact component = {ForgotPasswordReset}/>
            <PolicyRoute path = {Config.pageUri.changePassword} exact component = {ChangePassword}/>
            <PolicyRoute path = {Config.pageUri.resetPassword} exact component = {ResetPassword}/>
            <PolicyRoute path = {Config.pageUri.lmsPrompt} exact component = {LMSPrompt}/>
            <PolicyRoute path = {Config.pageUri.ManagerAccessReview} exact component = {ManagerAccessReview}/>
            <PolicyRoute path = {Config.pageUri.mfaEnrollmentEnforce} exact component = {MFAEnrollmentEnforce}/>
            <PolicyRoute path = {Config.pageUri.dynamicPage} exact component = {DynamicContentPage}/>
            <Route path = {Config.pageUri.settings} exact component = {withHomeAuth(Settings)}/>
            <Route path = {Config.pageUri.home} exact component = {withHomeAuth(HomePage)}/>
            <Route path = {Config.pageUri.confirmationForm} exact component={ConfirmationPage}/>
            <Route path = {Config.pageUri.verifyUser} exact component = {ConfirmationPage}/>
            <Route path = '/login' exact component = {VerifyRedirect}/>
            <Route exact path = '/' render={() => <Redirect to={Config.pageUri.home} />} />
            <Route path = {Config.pageUri.mfa005} exact component = {MfaNotEnrolled}/>
            <Route path = {Config.pageUri.error} component = {ErrorPage}/>
            <Route component={NotFound}/>
          </Switch>
        </ErrorBoundaries>
      </div>
    </Router>
  );
}
