import {RESET_ERROR, AUTHENTICATION_SUCCESS, AUTHENTICATION_FAILED, SET_EMAIL, SET_DIRECTORY, POLICY_EXECUTION_SUCCESS} from '../constants/Action'
import {FORM_ERROR, OTHER_ERROR} from '../constants/Error'
import log from 'loglevel';

const initialState = {
    directoryId: '',
    authenticated: false,
    email: "",
    errorState: false,
    errorType : "",
    errorMessage : "",
    forgotPwdPossible: false
}

function retrieveErrorDetails(error) {
    log.debug("retrieveErrorDetails : ",error.response)
    let errorType = OTHER_ERROR
    let errorMessage = "Something Went Wrong";
    let forgotPwdPossible = false;
    let errorCode = "INT001";
    let statusCode = 500
    if(error.response){
        errorCode = error.response.data.errorCode;
        switch(error.response.status){
            case 400:
                errorType = OTHER_ERROR
                if(["AUTHN012","AUTHN013","AUTHN014","AUTHN015","AUTHN016","AUTHN017","AUTHN018","AUTHN019","AUTHN020","AUTHN021","MFA005"].includes(errorCode))
                    errorMessage= error.response.data.errorMessage
                else if(errorCode === "VLF005" || errorCode === "AUTHN010")
                    errorMessage = "Session expired"
                else{
                    errorType = FORM_ERROR
                    errorMessage= "Invalid Password"
                }
                break;
            case 401:
                if(errorCode === "AUTHN022"){
                    errorType = OTHER_ERROR
                    errorMessage = "Your account is disabled"
                } else if(errorCode == "AUTHN005") {
                    console.log("forgot password possible")
                    errorType = FORM_ERROR
                    errorMessage = "Please enter correct credentials"
                    forgotPwdPossible = true
                }
                else if(error.response.data.errorCode === "AUTHN007" || error.response.data.errorCode === "MFA003")
                    errorMessage = "Invalid OTP"
                else{
                    errorType = FORM_ERROR
                    errorMessage = "Please enter correct credentials"
                }
                break;
            case 403:
                errorType = OTHER_ERROR
                errorMessage = "You are not allowed to access this application"
                if(error.response.data.errorCode === "DV003")
                    errorMessage = "Please use Ola administered device to login"
                if(error.response.data.errorCode === "DV004")
                    errorMessage = "Your device is blacklisted for login"
                if(error.response.data.errorCode === "DV005")
                    errorMessage = "Please use chrome browser to access this app"
                break;
            case 404:
                errorMessage = "User not found"
                break;
            case 440:
                errorType = OTHER_ERROR
                errorMessage = "Session Expired"
                break;
            case 500:
                errorType = OTHER_ERROR
                if(errorCode === "AUTHN011")
                    errorMessage = "Retry limit exceeded. Please try after sometime"
                break;
            case 429:
                errorType = OTHER_ERROR
                if(error.response.data.errorCode === "MFA001" || errorCode === "AUTHN011")
                    errorMessage = "Retry limit exceeded. Please try after sometime"
                break;
        }
    }
    return {
        type: errorType,
        message: errorMessage,
        forgotPwdPossible: forgotPwdPossible,
        code: errorCode,
        statusCode: statusCode
    }
}


export default (state = initialState, action)=>{

    switch (action.type){
        case SET_EMAIL:
            return {...state, email: action.payload}
        case RESET_ERROR:
            return {...state, errorState: false, errorMessage: "", errorCode: "", errorStatusCode: ""}
        case AUTHENTICATION_SUCCESS:
            return {...state, authenticated: true, errorState: false, errorMessage: "",errorCode: "", errorStatusCode: "", policyExecutionEndpoint: ""}
        case AUTHENTICATION_SUCCESS:
            return {...state, policyExecutionEndpoint: ""}
        // case POLICY_EXECUTION_SUCCESS:
        //     return {...state, errorState: false, errorMessage: "",errorCode: "", errorStatusCode: "", policyExecutionEndpoint: action.payload.additional_info?.policyExecutionEndpoint}
        case AUTHENTICATION_FAILED:
            let error = retrieveErrorDetails(action.payload)
            return {...state, authenticated: false, errorState: true, errorMessage: error.message, errorType: error.type, forgotPwdPossible: error.forgotPwdPossible, errorCode: error.code, errorStatusCode: error.statusCode}
        case SET_DIRECTORY:
            console.log("action.payload", action.payload)
            return {...state, directoryId: action.payload.directoryId}
        default:
            return state;
    }
}