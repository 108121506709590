import React, { useState } from 'react';
import {Button, Typography} from '@material-ui/core'
import LoginContainer from '../Layout/LoginContainer'
import LoginButton from '../Layout/LoginButton';
import VerifiedEmail from './VerifiedEmail';
import {OTHER_ERROR } from '../../constants/Error';
import { useHistory } from 'react-router-dom';
import config from '../../config';

export default function LMSPrompt(props) {
    const [state, setState] = useState({
        oldPassword: '',
        newPassword: '',
        newPasswordRepeated: '',
        showPassword: 0,
        errorState: 0,
        errorMessage: ""
      });

    const history = useHistory();

console.log(history.location.state);

    const {skipsLeft, totalSkips} = history.location.state.additionalInfo;

    const skipHandle = (event)=>{
        props.handlePolicySubmit({
            skip: true
        })
    }

    const handleCompleteTrainingClick = (event)=>{
        event.preventDefault();
        window.open(config.securityTrainingUrl);
    }

    return (
        <LoginContainer
            title={props.title} 
            subtitle={props.subtitle}
            action="Submit"
            loading={props.loading}
            errorState={props.errorState && props.errorType === OTHER_ERROR}
            errorMessage={props.errorMessage}
            errorCode={props.errorCode}
            >
        <img src = '/images/lms-logo.png' alt= "lms-logo" width="100%"/>
        {props.email && 
            <VerifiedEmail
                value={props.email}
            />
        }
        <br/>
        <div className="text-center">
            <Typography variant="body2">You have not completed mandatory security training. Please complete as soon as possible.</Typography>
            <Typography variant="h6">{skipsLeft}/{totalSkips} Attempts Remaining</Typography>
        </div>
        <Button onClick={handleCompleteTrainingClick} color="primary" variant="contained" fullWidth loading={props.loading} style={{
                marginTop : '16px'
            }}>Complete Training</Button>
        {skipsLeft != 0 && <Button
            loading={props.loading}
            variant="contained"
            onClick={skipHandle}
            fullWidth
            style={{
                marginTop : '16px'
            }}> Skip </Button>}
        </LoginContainer>
    )
}