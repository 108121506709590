/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var starlord_messages_request_search_users_request_pb = require('../../starlord/messages/request/search_users_request_pb.js')

var starlord_messages_request_get_all_users_request_pb = require('../../starlord/messages/request/get_all_users_request_pb.js')

var starlord_messages_request_get_users_by_soid_request_pb = require('../../starlord/messages/request/get_users_by_soid_request_pb.js')

var starlord_messages_response_user_search_response_pb = require('../../starlord/messages/response/user_search_response_pb.js')

var starlord_messages_models_ldap_complete_user_details_pb = require('../../starlord/messages/models/ldap_complete_user_details_pb.js')

var starlord_messages_request_get_complete_user_details_request_pb = require('../../starlord/messages/request/get_complete_user_details_request_pb.js')

var starlord_messages_response_multi_application_response_pb = require('../../starlord/messages/response/multi_application_response_pb.js')

var starlord_messages_request_user_assigned_applications_request_pb = require('../../starlord/messages/request/user_assigned_applications_request_pb.js')
const proto = require('./user_resource_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.UserResourceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.UserResourcePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetAllUsersRequest,
 *   !proto.UserSearchResponse>}
 */
const methodInfo_UserResource_getAllUsers = new grpc.web.AbstractClientBase.MethodInfo(
  starlord_messages_response_user_search_response_pb.UserSearchResponse,
  /** @param {!proto.GetAllUsersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  starlord_messages_response_user_search_response_pb.UserSearchResponse.deserializeBinary
);


/**
 * @param {!proto.GetAllUsersRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.UserSearchResponse>}
 *     The XHR Node Readable Stream
 */
proto.UserResourceClient.prototype.getAllUsers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/UserResource/getAllUsers',
      request,
      metadata || {},
      methodInfo_UserResource_getAllUsers);
};


/**
 * @param {!proto.GetAllUsersRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.UserSearchResponse>}
 *     The XHR Node Readable Stream
 */
proto.UserResourcePromiseClient.prototype.getAllUsers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/UserResource/getAllUsers',
      request,
      metadata || {},
      methodInfo_UserResource_getAllUsers);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.SearchUsersRequest,
 *   !proto.UserSearchResponse>}
 */
const methodInfo_UserResource_searchUsers = new grpc.web.AbstractClientBase.MethodInfo(
  starlord_messages_response_user_search_response_pb.UserSearchResponse,
  /** @param {!proto.SearchUsersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  starlord_messages_response_user_search_response_pb.UserSearchResponse.deserializeBinary
);


/**
 * @param {!proto.SearchUsersRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.UserSearchResponse>}
 *     The XHR Node Readable Stream
 */
proto.UserResourceClient.prototype.searchUsers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/UserResource/searchUsers',
      request,
      metadata || {},
      methodInfo_UserResource_searchUsers);
};


/**
 * @param {!proto.SearchUsersRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.UserSearchResponse>}
 *     The XHR Node Readable Stream
 */
proto.UserResourcePromiseClient.prototype.searchUsers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/UserResource/searchUsers',
      request,
      metadata || {},
      methodInfo_UserResource_searchUsers);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetUsersBySoidRequest,
 *   !proto.UserSearchResponse>}
 */
const methodInfo_UserResource_getUsersBySoid = new grpc.web.AbstractClientBase.MethodInfo(
  starlord_messages_response_user_search_response_pb.UserSearchResponse,
  /** @param {!proto.GetUsersBySoidRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  starlord_messages_response_user_search_response_pb.UserSearchResponse.deserializeBinary
);


/**
 * @param {!proto.GetUsersBySoidRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.UserSearchResponse>}
 *     The XHR Node Readable Stream
 */
proto.UserResourceClient.prototype.getUsersBySoid =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/UserResource/getUsersBySoid',
      request,
      metadata || {},
      methodInfo_UserResource_getUsersBySoid);
};


/**
 * @param {!proto.GetUsersBySoidRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.UserSearchResponse>}
 *     The XHR Node Readable Stream
 */
proto.UserResourcePromiseClient.prototype.getUsersBySoid =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/UserResource/getUsersBySoid',
      request,
      metadata || {},
      methodInfo_UserResource_getUsersBySoid);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetCompleteUserDetailsRequest,
 *   !proto.LdapCompleteUserDetails>}
 */
const methodInfo_UserResource_getCompleteUserDetails = new grpc.web.AbstractClientBase.MethodInfo(
  starlord_messages_models_ldap_complete_user_details_pb.LdapCompleteUserDetails,
  /** @param {!proto.GetCompleteUserDetailsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  starlord_messages_models_ldap_complete_user_details_pb.LdapCompleteUserDetails.deserializeBinary
);


/**
 * @param {!proto.GetCompleteUserDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.LdapCompleteUserDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.LdapCompleteUserDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.UserResourceClient.prototype.getCompleteUserDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/UserResource/getCompleteUserDetails',
      request,
      metadata || {},
      methodInfo_UserResource_getCompleteUserDetails,
      callback);
};


/**
 * @param {!proto.GetCompleteUserDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.LdapCompleteUserDetails>}
 *     A native promise that resolves to the response
 */
proto.UserResourcePromiseClient.prototype.getCompleteUserDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/UserResource/getCompleteUserDetails',
      request,
      metadata || {},
      methodInfo_UserResource_getCompleteUserDetails);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.UserAssignedApplicationsRequest,
 *   !proto.MultiApplicationResponse>}
 */
const methodInfo_UserResource_getUserAssignedApplications = new grpc.web.AbstractClientBase.MethodInfo(
  starlord_messages_response_multi_application_response_pb.MultiApplicationResponse,
  /** @param {!proto.UserAssignedApplicationsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  starlord_messages_response_multi_application_response_pb.MultiApplicationResponse.deserializeBinary
);


/**
 * @param {!proto.UserAssignedApplicationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.MultiApplicationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.MultiApplicationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.UserResourceClient.prototype.getUserAssignedApplications =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/UserResource/getUserAssignedApplications',
      request,
      metadata || {},
      methodInfo_UserResource_getUserAssignedApplications,
      callback);
};


/**
 * @param {!proto.UserAssignedApplicationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.MultiApplicationResponse>}
 *     A native promise that resolves to the response
 */
proto.UserResourcePromiseClient.prototype.getUserAssignedApplications =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/UserResource/getUserAssignedApplications',
      request,
      metadata || {},
      methodInfo_UserResource_getUserAssignedApplications);
};


module.exports = proto;

