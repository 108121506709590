import {UserResourceClient} from '../proto/starlord/services/user_resource_grpc_web_pb';
import {ApplicationControllerClient} from '../proto/starlord/services/application_resource_grpc_web_pb';
import Config from '../config';
import Cookies from 'universal-cookie';

export function getTenantId() {
    return Config.tenantId;
}

export function getApplicationResourceGRPCClient() {
    const resourceClient = new ApplicationControllerClient(Config.proxyServer, null, {
        'withCredentials': true
      });
    return resourceClient;
}

export function getUserSearchResourceGRPCClient() {
    const resourceClient = new UserResourceClient(Config.proxyServer, null, {
        'withCredentials': true
      }); 
    return resourceClient;
}

export function getMetadata() {
    var cookie = new Cookies();
    var sid = cookie.get("session_id");
    return {"sid": sid}
}