import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Troubleshoot from './Troubleshoot'
import { Button } from '@material-ui/core';
import '../../../stylesheet/error.css'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    border: 0
  },
  loginButton: {
    display: "flex",
    width: '150px',
    marginTop: '40px',
  }
}));

export default function MyError(props) {
  const classes = useStyles();

  const {code, message} = props

  var title = "Something went wrong";
  var description = "Not able to fetch applications properly"
  if(code === 16){
    title = "Unauthorized"
    description = "Your don't have permission to access this resource. Your session might have got invalid. Try logging again."
  }

  return (
        <div style={{width:"100%"}}>
          <div style={{display: "table",
            margin: "0 auto", padding: "50px"}}>
              <img id="error-icon" src="/icons/error.svg" style={{marginBottom: "20px"}}/>
              <h3 style={{fontWeight: '600'}}>{title}</h3>
              <p>{description}</p>
              <br/>
              <Button href="/home" variant="contained" color="primary" style={{marginBottom: '12px'}}>Login again</Button>
              <Troubleshoot 
                  body={
                      <div>
                      <strong>Transaction Id</strong>
                      <p>{sessionStorage.getItem("xTxnId")}</p>
                      </div>
                  }
              />
          </div>
        </div>
  );
}