import React, { useEffect, useState } from "react";
import { GetCompleteUserDetails, GetUserAssignedApplications, getMfaEnrolStatus, startSelfMfaEnroll } from '../../action/User'
import { GetAllApplicationsAction } from '../../action/Application'
import {useDispatch} from 'react-redux'
import NavbarPage from "../NavBar";
import config from "../../config";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Toolbar, Grid, Paper, Button} from "@material-ui/core";
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import {connect} from 'react-redux'
import MyError from '../Layout/ErrorRpc';
import OlaLoader from "../Loader";
import { AUTHENTICATION_FAILED, POLICY_EXECUTION_SUCCESS, RESET_ERROR, RESET_POLICY_EXECUTION_ENDPOINT } from "../../constants/Action";
import log from "loglevel";
import Axios from "axios";
import { isCompleteUri, retrieveUri } from "../../helpers/utils";
import {CHANGE_PWD_INIT_URL} from '../../constants/Url'
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import MfaEnrollComponent from'./mfaenrol'

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '88%',
      backgroundColor: '#92C83E',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 0,
    color: '#000',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    marginRight: '40px',
    '&:focus': {
      opacity: 1,
    },
    '&:hover': {
      opacity: 1,
    },
    '&.Mui-selected': {
      fontWeight: 800,
    }
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '40px',
    paddingRight: '40px',
    backgroundColor: '#F3F4F5'
  },
  toolbar: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  paper: {
    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    border: 'none',
    marginLeft: '10px',
    marginRight: '10px',
    padding: '18px'
  },
  infoAttribute: {
    padding: '8px'
  }
}));


function Settings(props) {
  const [state, setState] = useState({
    searchKey: ""
  })
  const dispatch = useDispatch()
  const [value, setValue] = React.useState("1");
  const [buttonLoading, setButtonLoading] = useState(false);

  
  const history = useHistory();
  

  useEffect(()=> {
    dispatch(GetCompleteUserDetails(sessionStorage.getItem('login_id'), getMfaEnrolStatus))
    }
  , [])

  // useEffect(() => {
  //   dispatch(getMfaEnrolStatus(sessionStorage.getItem('login_id'), window.REACT_APP_TENANT_ID))
  // }, [])
  // useEffect(()=> {
  //   if(value==="1"){
  //     dispatch(GetUserAssignedApplications(sessionStorage.getItem("internal_id"))) 
  //   } else {
  //     dispatch(GetAllApplicationsAction({tenantId: config.tenantId, pageNum: 1, pageSize: 10}))
  //     dispatch(GetUserAssignedApplications(sessionStorage.getItem("internal_id"))) 
  //   }
  // }, [value])

  


  
  const handlePasswordChange = (event)=> {
    event.preventDefault();
    dispatch({ type: RESET_ERROR })
    dispatch({ type: RESET_POLICY_EXECUTION_ENDPOINT })
    setButtonLoading(true);
    Axios.post(CHANGE_PWD_INIT_URL, {}).then((response)=>{
        if(response.status === 200){
            dispatch({ type: POLICY_EXECUTION_SUCCESS, payload: response.data})
            if(!response.data.is_logged_in){
              history.push(retrieveUri(response.data.policy_page_url), {additionalInfo: response.data.additional_info});
            }
            setButtonLoading(false);
        }
    }).catch((error)=>{
        setButtonLoading(false);
        log.debug("Change Pwd Init error...", error);
        dispatch({ type: AUTHENTICATION_FAILED, payload: error })
        history.push(config.pageUri.error, {status: error.response.status, statusText: error.response.statusText, headers: error.response.headers})
    })
  }

  const getMFAEnrollmentStatus = (mfaEnrolled) => {
    switch (mfaEnrolled) {
      case 0:
        return (<strong>Not Enrolled </strong>)
        break;
      case 1:
        return (<strong>Enrolled</strong>)
        break;
      case -1:
        return (<strong>{this.props.mfaError} </strong>)
        break;
      case 3:
        return (<strong>Un Known</strong>);
        break;
      case undefined:
        return (<strong>Un Known</strong>);
        break;
      default:
        return (<strong>Un Known</strong>);
        break;
    }
  }

  const classes = useStyles();

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  if(props.loading)
    return <div style={{marginTop: '300px'}}><OlaLoader/></div>
   else if(props.error)
        return (<MyError {...props.error}/>)
    else {
        return (
            <>
              <NavbarPage/>
              <div className={classes.root}>
              <TabContext value={value}>
                  <Toolbar>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      className={classes.toolbar}
                    >
                      <Grid >
                        <StyledTabs value={value} aria-label="ant example">
                          <StyledTab label="Personal Information" value="1"/>
                        </StyledTabs>
                      </Grid>
                      <Grid>
                        <Button color="primary" variant="contained" onClick={handlePasswordChange} style={{marginRight: ".5rem"}}>Change Password</Button>
                      </Grid>
                    </Grid>
                  </Toolbar>
                  <TabPanel value="1">
                  {props.error? <MyError title="Something went wrong" description="Not able to fetch user details"/>:
                    <>
                    <Paper className={classes.paper}>
                      <Grid container>
                        <Grid item md={6}>
                          <Grid container className={classes.infoAttribute}>
                            <Grid item md={4}><label>Username</label></Grid>
                            <Grid item md={8}><strong>{props.details.username}</strong></Grid>
                          </Grid>
                          <Grid container className={classes.infoAttribute}>
                            <Grid item md={4}><label>First name</label></Grid>
                            <Grid item md={8}><strong>{props.details.firstname}</strong></Grid>
                          </Grid>
                          <Grid container className={classes.infoAttribute}>
                            <Grid item md={4}><label>Last name</label></Grid>
                            <Grid item md={8}><strong>{props.details.lastname}</strong></Grid>
                          </Grid>
                          <Grid container className={classes.infoAttribute}>
                            <Grid item md={4}><label>Primary Email</label></Grid>
                            <Grid item md={8}><strong>{props.details.primaryemail}</strong></Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={6}>
                          <Grid container className={classes.infoAttribute}>
                            <Grid item md={4}><label>Mobile number</label></Grid>
                            <Grid item md={8}><strong>{props.details.mobilenumber}</strong></Grid>
                          </Grid>
                          <Grid container className={classes.infoAttribute}>
                            <Grid item md={4}><label>Job title</label></Grid>
                            <Grid item md={8}><strong>{props.details.jobtitle}</strong></Grid>
                          </Grid>
                          <Grid container className={classes.infoAttribute}>
                            <Grid item md={4}><label>Department</label></Grid>
                            <Grid item md={8}><strong>{props.details.department}</strong></Grid>
                          </Grid>
                          <Grid container className={classes.infoAttribute}>
                            <Grid item md={4}><label>Employee number</label></Grid>
                            <Grid item md={8}><strong>{props.details.employeenumber}</strong></Grid>
                          </Grid>                          
                        </Grid>
                        
                      </Grid>
                    </Paper>
                    </>}
                  </TabPanel>
                </TabContext>
                <TabContext value={value}>
                <Toolbar>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      className={classes.toolbar}
                    >
                      <Grid >
                        <StyledTabs value={value} aria-label="ant example">
                          <StyledTab label="Security Information" value="1"/>
                        </StyledTabs>
                      </Grid>
                      <Grid>
                        <MfaEnrollComponent/>
                      </Grid>
                    </Grid>
                  </Toolbar>
                  <TabPanel value="1">
                    <>
                    <Paper className={classes.paper}>
                      <Grid container>
                        <Grid item md={6}>
                          <Grid container className={classes.infoAttribute}>
                            <Grid item md={4}>
                              MFA Enroll Status
                            </Grid>
                            <Grid item md={8}>
                              {getMFAEnrollmentStatus(props.mfaEnrolled)}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={6}></Grid>
                      </Grid>
                      
                    </Paper>
                    </>
                  </TabPanel>
                </TabContext>
              </div>
            </>
            );
        }
    }

const mapDispatchToProps = (dispatch) => ({
    getSettingsAction: (paylaod) => {dispatch(GetCompleteUserDetails(paylaod))},
    getMfaEnrolStatus: (userId, tenantId) => {dispatch(getMfaEnrolStatus(userId, tenantId))},
    startSelfMfaEnroll: (userId, tenantId, otpType) => {dispatch(startSelfMfaEnroll(userId, tenantId, otpType))}
})

const mapStateToProps = (state) => ({
    details: state.user.details,
    loading: state.user.loading,
    error: state.user.error,
    mfaEnrolled : state.user.mfaEnrolled,
    enrolling : state.user.enrolling || false,
    mfaError : state.user.mfaError
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
