import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root:{
        paddingTop: '18px',
        paddingBottom: '18px'
    },
    alert: {
      width: '100%',
      boxShadow: 'none',
      borderRadius: 0,
      border: 'none'
    },
  }));

export default function PasswordInstruction() {
    const classes = useStyles()
    return(
        <div className={classes.root}>
            <Alert className={classes.alert} severity="info" icon={<svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.00016 0.333496C3.32016 0.333496 0.333496 3.32016 0.333496 7.00016C0.333496 10.6802 3.32016 13.6668 7.00016 13.6668C10.6802 13.6668 13.6668 10.6802 13.6668 7.00016C13.6668 3.32016 10.6802 0.333496 7.00016 0.333496ZM5.66683 10.3335L2.3335 7.00016L3.2735 6.06016L5.66683 8.44683L10.7268 3.38683L11.6668 4.3335L5.66683 10.3335Z" fill="#118840"/>
</svg>
}>
                <AlertTitle>Password should contain</AlertTitle>
                At least 8 characters, max 30 characters, a lower case letter, an upper case letter, a number (0-9), a symbol (e.g. ,!@#$%)
            </Alert>
            <Alert className={classes.alert} severity="info" icon={<svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.00016 0.333496C3.3135 0.333496 0.333496 3.3135 0.333496 7.00016C0.333496 10.6868 3.3135 13.6668 7.00016 13.6668C10.6868 13.6668 13.6668 10.6868 13.6668 7.00016C13.6668 3.3135 10.6868 0.333496 7.00016 0.333496ZM10.3335 9.3935L9.3935 10.3335L7.00016 7.94016L4.60683 10.3335L3.66683 9.3935L6.06016 7.00016L3.66683 4.60683L4.60683 3.66683L7.00016 6.06016L9.3935 3.66683L10.3335 4.60683L7.94016 7.00016L10.3335 9.3935Z" fill="#C91A3A"/>
</svg>
}>
                <AlertTitle>Password should not contain</AlertTitle>
                Part of username, first name, last name, last 10 passwords
            </Alert>
        </div>
    )
}