import React, {useEffect, useState} from 'react'
import config from '../../config';
import OlaLoader from '../Loader';
import Axios from 'axios';
import { useHistory, useParams } from 'react-router';
import { retrieveUri } from '../../helpers/utils';

export default function ForgotPwdInit(props){

    const [loading, setLoading] = useState(true)
    const history = useHistory()
    let { appId, tenantId } = useParams()

    useEffect(()=> {
        let params = (new URL(document.location)).searchParams;
        let token = params.get("fp_token");
        let session_id = params.get("session_id");
        Axios.get(config.server.basePath + config.server.contextpath + config.server.forgotPwdInitPath, 
          {
            params: {
                fp_token: token,
                session_id: session_id
            }
          })
        .then((response)=>{
            setLoading(false)
        if(response.status === 200){
          if(response.headers["content-type"].includes("text/html")){
            history.push(config.pageUri.confirmationForm, {data: response.data})
          }
          else if(response.data.is_logged_in){
            history.push(config.pageUri.home, {authenticated: true})
          }
          else if(!response.data.is_logged_in){
            history.push(retrieveUri(response.data.policy_page_url));
          }
        }
    }).catch((error)=>{
      setLoading(false)
      history.push(config.pageUri.error, {status: error.response.status, statusText: error.response.statusText, headers: error.response.headers, errorData : error.response.data})
    })
    }, [])
    
    if(loading)
        return (
          <div style={{marginTop: "300px"}}>
            <OlaLoader />
            <div className="text-center">
              Signing In...
            </div>
          </div>
        )
    return <></>
}