import React from 'react';
import { useSelector } from 'react-redux';
import { getDirectoryDisplayNameFromId, getLoginContainerTitle } from '../../helpers/utils';
import PasswordChangeComp from './PasswordChangeComp';

export default function ResetPassword(props) {
    const authState = useSelector(state => state.auth)
    const directoryName = getDirectoryDisplayNameFromId(authState.directoryId)

    return (
        <PasswordChangeComp title={getLoginContainerTitle("Reset Password", authState.directoryId)} {...props} skip={false}/>
    )
}