import { LOGOUT_URL, SESSION_VALIDATION_URL } from '../constants/Url';
import Axios from 'axios'

export const logout = (responseCallback, errorCallback)=>{
    Axios.post(LOGOUT_URL, {}).then((response) => {
        responseCallback(response);
    }).catch((error) => {
        errorCallback(error);
    })
}

export const sessionValidate = (responseCallback, errorCallback)=>{
    Axios.get(SESSION_VALIDATION_URL).then((response) => {
        responseCallback(response);
    }).catch((error) => {
        errorCallback(error);
    })
}