import React, { Component } from 'react'
import OlaLoader from '../Loader'
import { withRouter } from 'react-router-dom';


class ConfirmationForm extends Component {
    componentWillMount(){
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        }
    }
    componentDidMount(){
        document.body.onload = document.forms[0].submit()
    }
    render() {
        return (
            <React.Fragment>
                <div style={{marginTop: "300px"}}>
                    <OlaLoader />
                    <div className="text-center">
                        Please wait...
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{__html: this.props.location.state.data}}></div>
            </React.Fragment>
            
        )
    }
}

export default withRouter(ConfirmationForm)