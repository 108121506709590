import Config from '../config';

export const HOME_LOGIN_URL = Config.server.basePath + Config.server.contextpath + Config.server.homeLoginPath;
export const POLICY_AUTHN_URL = Config.server.basePath + Config.server.contextpath + Config.server.policyAuthnPath;
export const POLICY_AUTHN_MTLS_URL = Config.server.mtlsBasePath + Config.server.contextpath + Config.server.policyAuthnPath;
export const AUTHN_TRIGGER_OTP_URL = Config.server.basePath + Config.server.contextpath + Config.server.triggerOTPPath;
export const SESSION_VALIDATION_URL = Config.server.basePath + Config.server.contextpath + Config.server.sessionValidatePath;
export const LOGOUT_URL = Config.server.basePath + Config.server.contextpath + Config.server.logoutPath;
export const FORGOT_PWD_TOKEN_GEN_URL = Config.server.basePath + Config.server.contextpath + "/forgot-pwd/token/generate";
export const FORGOT_PWD_INIT_URL = Config.server.basePath + Config.server.contextpath + "/forgot-pwd/init";
export const CHANGE_PWD_INIT_URL = Config.server.basePath + Config.server.contextpath + Config.server.changePwdInitPath;
export const MFA_GET_ENROL_STATUS_URL = Config.mfa.enrollStatusUrl;
export const MFA_SELF_ENROL_START_URL = Config.mfa.selfEnrollStartUrl;
