import React, { useState } from "react";
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, MDBNavLink} from "mdbreact";
import {logout} from '../../action/Auth'
import config from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { AUTHENTICATION_FAILED } from "../../constants/Action";
import { useHistory } from "react-router";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  navbar: {
    boxShadow: 'none',
    paddingLeft: '68px',
    paddingRight: '68px',
    backgroundColor: '#fff',
  }
}));

export default function Navbar(props) {

  const classes = useStyles();

  const [state, setState] = useState({
    isOpen: false,
    isSnackbarOpen: false,
    errorMessage: ""
  });

  const history = useHistory();
  const profileName = useSelector(state => state.user.profileName)
  const disptach = useDispatch();

  const toggleCollapse = () => {
    setState({ isOpen: !state.isOpen });
  }

  const handleLogout = () => {
    logout(()=> {
      disptach({type: AUTHENTICATION_FAILED, payload: {errorMessage: "Logged Out"}})
      window.location = config.pageUri.home
    }, (error)=> {
      setState({
        ...state,
        isSnackbarOpen: true,
        errorMessage: error.toString()
      })
    })
  }

  return (
      <>
      <MDBNavbar light expand="md" className="custom-navbar">
        <MDBNavbarBrand href={config.pageUri.home}>
          <img src = '/images/logo_olacidm.svg' alt= "ola-logo"/>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={state.isOpen} navbar>
        <MDBNavbarNav right>
          <MDBNavItem active={history.location.pathname === config.pageUri.home}>
            <MDBNavLink to="/home">
              <Tooltip title="Home">
                <IconButton aria-label="home">
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 2.67188L15.0156 7.17188V15H13V9H7V15H4.98438V7.17188L10 2.67188ZM10 0L0.015625 9H3.01562V17.0156H9.01562V11.0156H10.9844V17.0156H16.9844V9H19.9844L10 0Z"
                    fill={history.location.pathname === config.pageUri.home?"#84B539":"black"}/>
                    </svg>
                </IconButton>
              </Tooltip>
            </MDBNavLink>
          </MDBNavItem>
          {/* <MDBNavItem active={history.location.pathname === '/settings'}>
            <MDBNavLink to="/settings">
              <Tooltip title="Setings">
                <IconButton aria-label="logout">
                  <Settings />
                </IconButton>
              </Tooltip>
            </MDBNavLink>
          </MDBNavItem> */}
          <MDBNavItem active={history.location.pathname === config.pageUri.settings}>
            <MDBNavLink to={config.pageUri.settings}>
              <Tooltip title="Account">
                <IconButton aria-label="account">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 3C10.0781 3 11.0156 3.89062 11.0156 5.01562C11.0156 6.09375 10.0781 6.98438 9 6.98438C7.92188 6.98438 6.98438 6.09375 6.98438 5.01562C6.98438 3.89062 7.92188 3 9 3ZM9 12.9844C11.7188 12.9844 14.8125 14.2969 15 15H3C3.23438 14.2969 6.32812 12.9844 9 12.9844ZM9 0.984375C6.79688 0.984375 5.01562 2.8125 5.01562 5.01562C5.01562 7.21875 6.79688 9 9 9C11.2031 9 12.9844 7.21875 12.9844 5.01562C12.9844 2.8125 11.2031 0.984375 9 0.984375ZM9 11.0156C6.32812 11.0156 0.984375 12.3281 0.984375 15V17.0156H17.0156V15C17.0156 12.3281 11.6719 11.0156 9 11.0156Z"
                  fill={history.location.pathname === config.pageUri.settings?"#84B539":"black"} fillOpacity="0.86"/>
                  </svg>
                </IconButton>
              </Tooltip>
              <span className="m-2" style={{color:history.location.pathname === config.pageUri.settings?"#84B539":"black"}}>{profileName}</span>
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem active={history.location.pathname === '/logout'}>
            <MDBNavLink to="#" onClick={handleLogout}>
              <Tooltip title="Logout">
                <IconButton aria-label="logout">
                  <svg width="20" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5H12V3H3V21H12V19H5V5Z" fill="black" fillOpacity="0.86"/>
                  <path d="M21 12L17 8V11H9V13H17V16L21 12Z" fill="black" fillOpacity="0.86"/>
                  </svg>
                </IconButton>
              </Tooltip>
            </MDBNavLink>
          </MDBNavItem>
        </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
      </>
    );
}
