// source: starlord/services/user_resource.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var starlord_messages_request_search_users_request_pb = require('../../starlord/messages/request/search_users_request_pb.js');
goog.object.extend(proto, starlord_messages_request_search_users_request_pb);
var starlord_messages_request_get_all_users_request_pb = require('../../starlord/messages/request/get_all_users_request_pb.js');
goog.object.extend(proto, starlord_messages_request_get_all_users_request_pb);
var starlord_messages_request_get_users_by_soid_request_pb = require('../../starlord/messages/request/get_users_by_soid_request_pb.js');
goog.object.extend(proto, starlord_messages_request_get_users_by_soid_request_pb);
var starlord_messages_response_user_search_response_pb = require('../../starlord/messages/response/user_search_response_pb.js');
goog.object.extend(proto, starlord_messages_response_user_search_response_pb);
var starlord_messages_models_ldap_complete_user_details_pb = require('../../starlord/messages/models/ldap_complete_user_details_pb.js');
goog.object.extend(proto, starlord_messages_models_ldap_complete_user_details_pb);
var starlord_messages_request_get_complete_user_details_request_pb = require('../../starlord/messages/request/get_complete_user_details_request_pb.js');
goog.object.extend(proto, starlord_messages_request_get_complete_user_details_request_pb);
var starlord_messages_response_multi_application_response_pb = require('../../starlord/messages/response/multi_application_response_pb.js');
goog.object.extend(proto, starlord_messages_response_multi_application_response_pb);
var starlord_messages_request_user_assigned_applications_request_pb = require('../../starlord/messages/request/user_assigned_applications_request_pb.js');
goog.object.extend(proto, starlord_messages_request_user_assigned_applications_request_pb);
