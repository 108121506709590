// source: starlord/services/application_resource.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var starlord_messages_response_application_response_pb = require('../../starlord/messages/response/application_response_pb.js');
goog.object.extend(proto, starlord_messages_response_application_response_pb);
var starlord_messages_response_multi_application_response_pb = require('../../starlord/messages/response/multi_application_response_pb.js');
goog.object.extend(proto, starlord_messages_response_multi_application_response_pb);
var starlord_messages_response_application_assignment_response_pb = require('../../starlord/messages/response/application_assignment_response_pb.js');
goog.object.extend(proto, starlord_messages_response_application_assignment_response_pb);
var starlord_messages_response_generic_response_pb = require('../../starlord/messages/response/generic_response_pb.js');
goog.object.extend(proto, starlord_messages_response_generic_response_pb);
var starlord_messages_request_get_application_request_pb = require('../../starlord/messages/request/get_application_request_pb.js');
goog.object.extend(proto, starlord_messages_request_get_application_request_pb);
var starlord_messages_request_application_assignment_unassignment_request_pb = require('../../starlord/messages/request/application_assignment_unassignment_request_pb.js');
goog.object.extend(proto, starlord_messages_request_application_assignment_unassignment_request_pb);
var starlord_messages_request_aws_application_assignment_unassignment_request_pb = require('../../starlord/messages/request/aws_application_assignment_unassignment_request_pb.js');
goog.object.extend(proto, starlord_messages_request_aws_application_assignment_unassignment_request_pb);
var starlord_messages_request_get_application_assignment_request_pb = require('../../starlord/messages/request/get_application_assignment_request_pb.js');
goog.object.extend(proto, starlord_messages_request_get_application_assignment_request_pb);
var starlord_messages_request_get_all_applications_request_pb = require('../../starlord/messages/request/get_all_applications_request_pb.js');
goog.object.extend(proto, starlord_messages_request_get_all_applications_request_pb);
var starlord_messages_request_application_upsert_request_pb = require('../../starlord/messages/request/application_upsert_request_pb.js');
goog.object.extend(proto, starlord_messages_request_application_upsert_request_pb);
var starlord_messages_request_application_delete_request_pb = require('../../starlord/messages/request/application_delete_request_pb.js');
goog.object.extend(proto, starlord_messages_request_application_delete_request_pb);
