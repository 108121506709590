import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../Loader';

const useStyles = makeStyles((theme) => ({
    button: {
      marginTop: ({marginTop}) => marginTop || '40px'
    }
  }));

export default function LoginButton(props) {
    const classes = useStyles(props);
    return (
        <Button className={classes.button} variant="contained" color="primary" type={props.type?props.type:"submit"} fullWidth disabled={props.loading} onClick={props.onClick}>
            {props.loading?
              <Loader width={60} height={60}/>:props.children
            }
        </Button>
    );
}