import React, { useState , useEffect} from 'react'
import {Route, useHistory} from 'react-router-dom'
import axios from 'axios';
import { POLICY_AUTHN_MTLS_URL, POLICY_AUTHN_URL } from '../constants/Url';
import config from '../config'
import { useDispatch, useSelector } from 'react-redux'
import { AUTHENTICATION_FAILED, AUTHENTICATION_SUCCESS, POLICY_EXECUTION_SUCCESS, RESET_ERROR, RESET_POLICY_EXECUTION_ENDPOINT, SET_EMAIL } from '../constants/Action';
import {isCompleteUri, retrieveUri} from './utils'
import { sessionValidate } from '../action/Auth';
import OlaLoader from "../components/Loader"
import Snackbar from '@material-ui/core/Snackbar';
import { useLocation } from 'react-router-dom'
import log from 'loglevel';

export default function PolicyRoute(props){
  const { component: Component, ...remainingProps } = props
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [validating, setValidating] = useState(true);
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [toastrMessage, setToastrMessage] = useState("");
  var selectedDirectoryId = auth.directoryId

  if(config.directory.useDefault  == "true")
    selectedDirectoryId = config.directory.defaultDirectoryId


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  /* blocking back call */
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function(event) {
    history.go(1);
  };

  useEffect(() => {
    dispatch({ type: RESET_ERROR })
    sessionValidate((response)=> {
      console.log("response", response);
      dispatch({type: SET_EMAIL, payload: response.data.policyPipelineSession.loginId})
      sessionStorage.setItem("login_id", response.data.policyPipelineSession.loginId)
      sessionStorage.setItem("internal_id", response.data.policyPipelineSession.olaId)
      setValidating(false)
    }, (error)=> {
      setValidating(false)
      history.push(config.pageUri.error, {status: error.response.status, statusText: error.response.statusText, headers: error.response.headers})
    })
  }, []);

  function getSuccessMessage(isLoggedIn, payload) {
    if(isLoggedIn)
      return "Login successful"
    else if(payload.skip)
      return "Skip successful"
    else if(location.pathname === config.pageUri.forgotPasswordReset)
      return "Password reset successful. Please login again."
    else if(location.pathname === config.pageUri.resetPassword)
      return "Password change successful. Please login again."
    else if(location.pathname === config.pageUri.changePassword)
      return "Password change successful"
    else if(location.pathname === config.pageUri.mfaTotpCode || location.pathname === config.pageUri.mfaFotpCode || location.pathname === config.pageUri.mfaHotpCode || location.pathname === config.pageUri.mfaRotpCode || location.pathname === config.pageUri.smsAndRmOtpCode)
      return "OTP Verfied"
  }

  const showToastr = (isLoggedIn, payload) => {
    setOpen(true);
    setToastrMessage(getSuccessMessage(isLoggedIn, payload));
  }

  const verifyCredentials = (payload) => {
    dispatch({ type: RESET_ERROR })
    setLoading(true)
    let policyExecutionEndpoint = POLICY_AUTHN_URL;
    // if(auth.policyExecutionEndpoint != undefined && auth.policyExecutionEndpoint != "")
    //   policyExecutionEndpoint = auth.policyExecutionEndpoint;
    console.log("query params...", history.location.search)
    if(history.location.search.includes("dc=true"))
      policyExecutionEndpoint = POLICY_AUTHN_MTLS_URL;
    axios.post(policyExecutionEndpoint, {credentials: payload, directoryId: selectedDirectoryId}).then((response)=>{
      setLoading(false)
      log.debug("verifyCredentials ",response)
      if(response.status === 200){
          // dispatch({ type: RESET_POLICY_EXECUTION_ENDPOINT })
          if(response.headers["content-type"].includes("text/html")){
            showToastr(true, payload);
            setTimeout(() => {
              history.push(config.pageUri.confirmationForm, {data: response.data})
            }, 1000)
          }
          else if(response.data.is_logged_in){
            dispatch({ type: AUTHENTICATION_SUCCESS })
            // dispatch({ type: RESET_POLICY_EXECUTION_ENDPOINT })
            sessionStorage.setItem("login_id", response.headers["x-login-id"])
            sessionStorage.setItem("internal_id", response.headers["x-internal-id"])
            showToastr(response.data.is_logged_in, payload);
            setTimeout(() => {
              history.push(config.pageUri.home, {authenticated: true})
            }, 1000)
          }
          else if(!response.data.is_logged_in){
            // dispatch({ type: POLICY_EXECUTION_SUCCESS, payload: response.data})
            console.log("location.pathname", location.pathname);
            if(location.pathname !== config.pageUri.username && location.pathname !== config.pageUri.password){
              showToastr(response.data.is_logged_in, payload);
              setTimeout(() => {
                history.push(retrieveUri(response.data.policy_page_url), {additionalInfo: response.data.additional_info});
              }, 1000);
            } else {
              console.log(response.data.additional_info);
              history.push(retrieveUri(response.data.policy_page_url), {additionalInfo: response.data.additional_info});
              // history.push(retrieveUri(config.pageUri.lmsPrompt), {additionalInfo: {skipsLeft: 2, totalSkips: 10}});
            }
          }
        }
    }).catch((error)=>{
      log.debug("Policy authn error", error);
      setLoading(false)
      dispatch({type: AUTHENTICATION_FAILED, payload: error})
    })
  }

  return validating? <div style={{marginTop: '300px'}}><OlaLoader/></div> : <Route 
  {...remainingProps} 
  render={props => (
    <>
    <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal:'center'}} message={toastrMessage}/>
    <Component handlePolicySubmit={verifyCredentials} loading={validating || loading} {...auth}/>
    </>
  )} 
/>;
}