import React from 'react';
import { useSelector } from 'react-redux';
import { getDirectoryDisplayNameFromId, getLoginContainerTitle } from '../../helpers/utils';
import PasswordChangeComp from './PasswordChangeComp';

export default function ChangePassword(props) {
    const authState = useSelector(state => state.auth)
    const directoryName = getDirectoryDisplayNameFromId(authState.directoryId)
    return (
        <PasswordChangeComp title={getLoginContainerTitle("Change Password", authState.directoryId)} subtitle="Password Expiring Soon" {...props} skip={true}/>
    )
}