import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   '& > *': {
  //     margin: theme.spacing(1),
  //   }
  // },
  // avatar: {
  //   marginRight: '10px',
  // },
  // check: {
  //   color: '#10886A',
  // },
  // input: {
  //   width: '100%'
  // }
}));

export default function VerifiedEmail(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        className={classes.input}
        label="Username"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img src='/icons/verified.svg'/>
            </InputAdornment>
          )
          }}
        value={props.value}
        autoComplete="username"
        readOnly
        disabled
      />
    </div>
  );
}
