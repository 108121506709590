import React, { useState } from 'react';
import {InputAdornment,IconButton, TextField} from '@material-ui/core'
import {Visibility, VisibilityOff} from '@material-ui/icons';
import LoginContainer from '../Layout/LoginContainer'
import LoginButton from '../Layout/LoginButton';
import VerifiedEmail from './VerifiedEmail';
import { FORM_ERROR, OTHER_ERROR } from '../../constants/Error';
import { useSelector } from 'react-redux';
import { getDirectoryDisplayNameFromId, getLoginContainerTitle } from '../../helpers/utils';
import OtpInput from 'react-otp-input';

export default function MFAFotpCodeVerification(props) {
    const authState = useSelector(state => state.auth)
    const directoryName = getDirectoryDisplayNameFromId(authState.directoryId)
    const [state, setState] = useState({
        otp: '',
        showPassword: 0
      });

    const ShowPassword = (props)=>{
        return (
            <InputAdornment position="end">
                <IconButton
                    edge="end"
                    onClick={() => {handleClickShowPassword(props.value)}}>
                    {state.showPassword === props.value? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        )
    }

    const handleClickShowPassword = (value) =>{
        setState({
            ...state,
            showPassword: state.showPassword===value?0:value
        })
    }

    const onInputChange = (value)=>{
        console.log(value);
        setState({
            ...state,
            otp: value
        })
    }

    const onSubmit = (event)=>{
        event.preventDefault();
        props.handlePolicySubmit({
            otp : state.otp
        })
    }

    return (
        <div className="login-sm-container">
            <LoginContainer title={getLoginContainerTitle("2FA Verification", authState.directoryId)}
            onSubmit={onSubmit} loading={props.loading} action="Submit" errorState={props.errorState && props.errorType === OTHER_ERROR}
            errorCode={props.errorCode}
            errorMessage={props.errorMessage}>
                {props.email && 
                    <VerifiedEmail
                        value={props.email}
                    />
                }
                <TextField
                    id="outlined-full-width"
                    autoComplete='off'
                    inputProps={{
                        autocomplete: 'off',
                    }}
                    label="Code"
                    required
                    type='password'
                    value = {state.otp}
                    onChange = {onInputChange}
                    error = {props.errorState  && props.errorType === FORM_ERROR}
                    helperText = {props.errorState && props.errorType === FORM_ERROR?props.errorMessage:"Enter the 6 digit code."}
                    disabled={props.loading}
                    inputRef={input => input && input.focus()}
                />
                
                {/* <OtpInput
          onChange={onInputChange}
          numInputs={6}
          inputStyle={{height:'50px',width:'100%',borderRadius:'0',color:'#000',border: '1px solid rgba(0, 0, 0, 0.12)', caretColor: '#16AA51',marginRight:'5px',marginLeft:'5px'}}
          value={state.otp}
          focusStyle={{backgroundColor:'#F3F4F5',border:'2px solid #16AA51'}}
          isInputNum={true}
          ariaLabel="Hello"
          isDisabled={props.loading}
        /> */}
                <LoginButton onSubmit={onSubmit} loading={props.loading}>Submit</LoginButton>
            </LoginContainer>
        </div>
    )
}
