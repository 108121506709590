import React, { useState } from 'react';
import {InputAdornment,IconButton, TextField} from '@material-ui/core'
import {Visibility, VisibilityOff} from '@material-ui/icons';
import LoginContainer from '../Layout/LoginContainer'
import LoginButton from '../Layout/LoginButton';
import VerifiedEmail from './VerifiedEmail';
import { FORM_ERROR, OTHER_ERROR } from '../../constants/Error';
import { useSelector } from 'react-redux';
import { getDirectoryDisplayNameFromId, getLoginContainerTitle } from '../../helpers/utils';

export default function MFATotpCodeVerification(props) {
    const authState = useSelector(state => state.auth)
    const directoryName = getDirectoryDisplayNameFromId(authState.directoryId)
    const [state, setState] = useState({
        otp: '',
        showPassword: 0
      });

    const ShowPassword = (props)=>{
        return (
            <InputAdornment position="end">
                <IconButton
                    edge="end"
                    onClick={() => {handleClickShowPassword(props.value)}}>
                    {state.showPassword === props.value? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        )
    }

    const handleClickShowPassword = (value) =>{
        setState({
            ...state,
            showPassword: state.showPassword===value?0:value
        })
    }

    const onInputChange = (event)=>{
        setState({
            ...state,
            otp: event.target.value
        })
    }

    const onSubmit = (event)=>{
        event.preventDefault();
        props.handlePolicySubmit({
            otp : state.otp
        })
    }

    return (
        <div className="login-sm-container">
            <LoginContainer title={getLoginContainerTitle("2FA Verification", authState.directoryId)} 
            onSubmit={onSubmit} loading={props.loading} action="Submit" errorState={props.errorState && props.errorType === OTHER_ERROR}
            errorCode={props.errorCode}
            errorMessage={props.errorMessage}>
               {props.email && 
                    <VerifiedEmail
                        value={props.email}
                    />
                }
                <TextField
                    id="outlined-full-width"
                    autoComplete='off'
                    inputProps={{
                        autocomplete: 'off',
                    }}
                    label="Code"
                    required
                    type='password'
                    value = {state.otp}
                    onChange = {onInputChange}
                    error = {props.errorState  && props.errorType === FORM_ERROR}
                    helperText = {props.errorState && props.errorType === FORM_ERROR?props.errorMessage:"Enter the code from registered MFA application."}
                    disabled={props.loading}
                    inputRef={input => input && input.focus()}
                />
                <LoginButton onSubmit={onSubmit} loading={props.loading}>Submit</LoginButton>
            </LoginContainer>
        </div>
    )
}
