// source: starlord/messages/models/saml_app_authn_attributes.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var starlord_messages_models_assertion_consumer_service_pb = require('../../../starlord/messages/models/assertion_consumer_service_pb.js');
goog.object.extend(proto, starlord_messages_models_assertion_consumer_service_pb);
var starlord_messages_models_saml_attribute_statement_pb = require('../../../starlord/messages/models/saml_attribute_statement_pb.js');
goog.object.extend(proto, starlord_messages_models_saml_attribute_statement_pb);
var starlord_messages_models_saml_group_attribute_statement_pb = require('../../../starlord/messages/models/saml_group_attribute_statement_pb.js');
goog.object.extend(proto, starlord_messages_models_saml_group_attribute_statement_pb);
goog.exportSymbol('proto.SamlAppAuthnAttributes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SamlAppAuthnAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SamlAppAuthnAttributes.repeatedFields_, null);
};
goog.inherits(proto.SamlAppAuthnAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SamlAppAuthnAttributes.displayName = 'proto.SamlAppAuthnAttributes';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SamlAppAuthnAttributes.repeatedFields_ = [1,15,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SamlAppAuthnAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.SamlAppAuthnAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SamlAppAuthnAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SamlAppAuthnAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    assertionconsumerservicesList: jspb.Message.toObjectList(msg.getAssertionconsumerservicesList(),
    starlord_messages_models_assertion_consumer_service_pb.AssertionConsumerService.toObject, includeInstance),
    audienceuri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    defaultrelaystate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    nameidformat: jspb.Message.getFieldWithDefault(msg, 4, ""),
    applicationusername: jspb.Message.getFieldWithDefault(msg, 5, ""),
    responsesigned: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    assertionsigned: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    signaturealgorithm: jspb.Message.getFieldWithDefault(msg, 8, ""),
    digestalgorithm: jspb.Message.getFieldWithDefault(msg, 9, ""),
    assertionencrypted: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    enablesinglelogout: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    honorforceauthentication: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    authncontextclass: jspb.Message.getFieldWithDefault(msg, 13, ""),
    additinalfieldsMap: (f = msg.getAdditinalfieldsMap()) ? f.toObject(includeInstance, undefined) : [],
    attributestatementsList: jspb.Message.toObjectList(msg.getAttributestatementsList(),
    starlord_messages_models_saml_attribute_statement_pb.SamlAttributeStatement.toObject, includeInstance),
    groupattributestatementsList: jspb.Message.toObjectList(msg.getGroupattributestatementsList(),
    starlord_messages_models_saml_group_attribute_statement_pb.SamlGroupAttributeStatement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SamlAppAuthnAttributes}
 */
proto.SamlAppAuthnAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SamlAppAuthnAttributes;
  return proto.SamlAppAuthnAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SamlAppAuthnAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SamlAppAuthnAttributes}
 */
proto.SamlAppAuthnAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new starlord_messages_models_assertion_consumer_service_pb.AssertionConsumerService;
      reader.readMessage(value,starlord_messages_models_assertion_consumer_service_pb.AssertionConsumerService.deserializeBinaryFromReader);
      msg.addAssertionconsumerservices(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudienceuri(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultrelaystate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNameidformat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplicationusername(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResponsesigned(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAssertionsigned(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignaturealgorithm(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDigestalgorithm(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAssertionencrypted(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnablesinglelogout(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHonorforceauthentication(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthncontextclass(value);
      break;
    case 14:
      var value = msg.getAdditinalfieldsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 15:
      var value = new starlord_messages_models_saml_attribute_statement_pb.SamlAttributeStatement;
      reader.readMessage(value,starlord_messages_models_saml_attribute_statement_pb.SamlAttributeStatement.deserializeBinaryFromReader);
      msg.addAttributestatements(value);
      break;
    case 16:
      var value = new starlord_messages_models_saml_group_attribute_statement_pb.SamlGroupAttributeStatement;
      reader.readMessage(value,starlord_messages_models_saml_group_attribute_statement_pb.SamlGroupAttributeStatement.deserializeBinaryFromReader);
      msg.addGroupattributestatements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SamlAppAuthnAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SamlAppAuthnAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SamlAppAuthnAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SamlAppAuthnAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssertionconsumerservicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      starlord_messages_models_assertion_consumer_service_pb.AssertionConsumerService.serializeBinaryToWriter
    );
  }
  f = message.getAudienceuri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDefaultrelaystate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNameidformat();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getApplicationusername();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getResponsesigned();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAssertionsigned();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSignaturealgorithm();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDigestalgorithm();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAssertionencrypted();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getEnablesinglelogout();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getHonorforceauthentication();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getAuthncontextclass();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAdditinalfieldsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(14, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getAttributestatementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      starlord_messages_models_saml_attribute_statement_pb.SamlAttributeStatement.serializeBinaryToWriter
    );
  }
  f = message.getGroupattributestatementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      starlord_messages_models_saml_group_attribute_statement_pb.SamlGroupAttributeStatement.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AssertionConsumerService assertionConsumerServices = 1;
 * @return {!Array<!proto.AssertionConsumerService>}
 */
proto.SamlAppAuthnAttributes.prototype.getAssertionconsumerservicesList = function() {
  return /** @type{!Array<!proto.AssertionConsumerService>} */ (
    jspb.Message.getRepeatedWrapperField(this, starlord_messages_models_assertion_consumer_service_pb.AssertionConsumerService, 1));
};


/**
 * @param {!Array<!proto.AssertionConsumerService>} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
*/
proto.SamlAppAuthnAttributes.prototype.setAssertionconsumerservicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.AssertionConsumerService=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AssertionConsumerService}
 */
proto.SamlAppAuthnAttributes.prototype.addAssertionconsumerservices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.AssertionConsumerService, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.clearAssertionconsumerservicesList = function() {
  return this.setAssertionconsumerservicesList([]);
};


/**
 * optional string audienceUri = 2;
 * @return {string}
 */
proto.SamlAppAuthnAttributes.prototype.getAudienceuri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.setAudienceuri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string defaultRelayState = 3;
 * @return {string}
 */
proto.SamlAppAuthnAttributes.prototype.getDefaultrelaystate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.setDefaultrelaystate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string nameidFormat = 4;
 * @return {string}
 */
proto.SamlAppAuthnAttributes.prototype.getNameidformat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.setNameidformat = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string applicationUsername = 5;
 * @return {string}
 */
proto.SamlAppAuthnAttributes.prototype.getApplicationusername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.setApplicationusername = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool responseSigned = 6;
 * @return {boolean}
 */
proto.SamlAppAuthnAttributes.prototype.getResponsesigned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.setResponsesigned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool assertionSigned = 7;
 * @return {boolean}
 */
proto.SamlAppAuthnAttributes.prototype.getAssertionsigned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.setAssertionsigned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string signatureAlgorithm = 8;
 * @return {string}
 */
proto.SamlAppAuthnAttributes.prototype.getSignaturealgorithm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.setSignaturealgorithm = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string digestAlgorithm = 9;
 * @return {string}
 */
proto.SamlAppAuthnAttributes.prototype.getDigestalgorithm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.setDigestalgorithm = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool assertionEncrypted = 10;
 * @return {boolean}
 */
proto.SamlAppAuthnAttributes.prototype.getAssertionencrypted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.setAssertionencrypted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool enableSingleLogout = 11;
 * @return {boolean}
 */
proto.SamlAppAuthnAttributes.prototype.getEnablesinglelogout = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.setEnablesinglelogout = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool honorForceAuthentication = 12;
 * @return {boolean}
 */
proto.SamlAppAuthnAttributes.prototype.getHonorforceauthentication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.setHonorforceauthentication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string authnContextClass = 13;
 * @return {string}
 */
proto.SamlAppAuthnAttributes.prototype.getAuthncontextclass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.setAuthncontextclass = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * map<string, string> additinalFields = 14;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.SamlAppAuthnAttributes.prototype.getAdditinalfieldsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 14, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.clearAdditinalfieldsMap = function() {
  this.getAdditinalfieldsMap().clear();
  return this;};


/**
 * repeated SamlAttributeStatement attributeStatements = 15;
 * @return {!Array<!proto.SamlAttributeStatement>}
 */
proto.SamlAppAuthnAttributes.prototype.getAttributestatementsList = function() {
  return /** @type{!Array<!proto.SamlAttributeStatement>} */ (
    jspb.Message.getRepeatedWrapperField(this, starlord_messages_models_saml_attribute_statement_pb.SamlAttributeStatement, 15));
};


/**
 * @param {!Array<!proto.SamlAttributeStatement>} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
*/
proto.SamlAppAuthnAttributes.prototype.setAttributestatementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.SamlAttributeStatement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SamlAttributeStatement}
 */
proto.SamlAppAuthnAttributes.prototype.addAttributestatements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.SamlAttributeStatement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.clearAttributestatementsList = function() {
  return this.setAttributestatementsList([]);
};


/**
 * repeated SamlGroupAttributeStatement groupAttributeStatements = 16;
 * @return {!Array<!proto.SamlGroupAttributeStatement>}
 */
proto.SamlAppAuthnAttributes.prototype.getGroupattributestatementsList = function() {
  return /** @type{!Array<!proto.SamlGroupAttributeStatement>} */ (
    jspb.Message.getRepeatedWrapperField(this, starlord_messages_models_saml_group_attribute_statement_pb.SamlGroupAttributeStatement, 16));
};


/**
 * @param {!Array<!proto.SamlGroupAttributeStatement>} value
 * @return {!proto.SamlAppAuthnAttributes} returns this
*/
proto.SamlAppAuthnAttributes.prototype.setGroupattributestatementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.SamlGroupAttributeStatement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SamlGroupAttributeStatement}
 */
proto.SamlAppAuthnAttributes.prototype.addGroupattributestatements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.SamlGroupAttributeStatement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SamlAppAuthnAttributes} returns this
 */
proto.SamlAppAuthnAttributes.prototype.clearGroupattributestatementsList = function() {
  return this.setGroupattributestatementsList([]);
};


goog.object.extend(exports, proto);
