import React, { useState } from 'react';
import {InputAdornment,IconButton, Icon, Button} from '@material-ui/core'
import LoginContainer from '../Layout/LoginContainer'
import TextField from '@material-ui/core/TextField'
import LoginButton from '../Layout/LoginButton';
import VerifiedEmail from './VerifiedEmail';
import PasswordInstruction from '../Misc/PasswordInstruction'
import { FORM_ERROR, OTHER_ERROR } from '../../constants/Error';
import { rsaEncrypt } from '../../helpers/encryption';
import { PWD_RSA_PUB } from '../../constants/Text';
import { useHistory } from 'react-router-dom';

export default function PasswordChangeComp(props) {
    const [state, setState] = useState({
        oldPassword: '',
        newPassword: '',
        newPasswordRepeated: '',
        showPassword: 0,
        errorState: 0,
        errorMessage: ""
      });
    
    const history = useHistory();

    const historyState = history.location.state;

    let customMessage = undefined;

    if(historyState != undefined && historyState.additionalInfo != undefined && historyState.additionalInfo.additionalMessage != undefined){
        customMessage = historyState.additionalInfo.additionalMessage;
    }

    const ShowPassword = (props)=>{
        return (
            <InputAdornment position="end">
                <IconButton
                    edge="end"
                    onClick={() => {handleClickShowPassword(props.value)}}>
                    {state.showPassword === props.value? <img src='/icons/hide.svg' /> : <img src='/icons/show.svg' />}
                </IconButton>
            </InputAdornment>
        )
    }

    const handleClickShowPassword = (value) =>{
        setState({
            ...state,
            showPassword: state.showPassword===value?0:value
        })
    }

    const onInputChange = (event)=>{
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    const skipHandle = (event)=>{
        props.handlePolicySubmit({
            skip: true
        })
    }

    const onSubmit = (event)=>{
        event.preventDefault();
        if(state.newPassword == state.oldPassword){
            setState({
                ...state,
                errorState: 1,
                errorMessage: "Your old password and new password should not match"
            })
          }
        else if(state.newPassword !== state.newPasswordRepeated){
            setState({
                ...state,
                errorState: 2,
                errorMessage: "Please confirm new password"
            })
        }
        else{
            setState({
                ...state,
                errorState: 0,
                errorMessage: ""
            })
            props.handlePolicySubmit({
                password : rsaEncrypt(state.oldPassword, PWD_RSA_PUB),
                newPassword : rsaEncrypt(state.newPassword, PWD_RSA_PUB),
                newPasswordRepeated : rsaEncrypt(state.newPasswordRepeated, PWD_RSA_PUB)
            })
        }
    }

    return (
        <LoginContainer
            title={props.title} 
            subtitle={props.subtitle}
            onSubmit={onSubmit}
            action="Submit"
            loading={props.loading}
            errorState={props.errorState && props.errorType === OTHER_ERROR}
            errorCode={props.errorCode}
            errorMessage={props.errorMessage}
            >
        <PasswordInstruction/>
        {customMessage != undefined && <div><p>{customMessage}</p></div>}
        {props.email && 
            <VerifiedEmail
                value={props.email}
            />
        }
        <TextField
            icon="key"
            name="oldPassword"
            label="Old password"
            autoComplete="current-password"
            required
            value = {state.oldPassword}
            onChange = {onInputChange}
            type={state.showPassword === 1? 'text':'password'}
            error = {props.errorState && props.errorType === FORM_ERROR}
            helperText = {props.errorState && props.errorType === FORM_ERROR?props.errorMessage:""}
            InputProps={{
                endAdornment: (
                <ShowPassword value={1}/>
                )
            }}
            disabled={props.loading}
            autoFocus
        />
        <TextField
            icon="key"
            name="newPassword"
            label="New password"
            required
            value = {state.newPassword}
            onChange = {onInputChange}
            type={state.showPassword === 2? 'text':'password'}
            error = {state.errorState===1}
            helperText = {state.errorState===1?state.errorMessage:null}
            InputProps={{
                endAdornment: (
                <ShowPassword value={2}/>
                )
            }}
            disabled={props.loading}
        />
        <TextField
            icon="key"
            name="newPasswordRepeated"
            label="Confirm new password"
            required
            value = {state.newPasswordRepeated}
            onChange = {onInputChange}
            type={state.showPassword === 3? 'text':'password'}
            error = {state.errorState===2}
            helperText = {state.errorState===2?state.errorMessage:null}
            InputProps={{
                endAdornment: (
                <ShowPassword value={3}/>
                )
            }}
            disabled={props.loading}
        />
        <LoginButton onSubmit={onSubmit} loading={props.loading}>Submit</LoginButton>
        {props.skip && 
            <Button
                variant="contained"
                onClick={skipHandle}
                fullWidth
                style={{
                    marginTop : '16px'
                }}> Skip </Button>
        }
        </LoginContainer>
    )
}