import React from 'react'
import config from '../../config'
import Troubleshoot from '../Layout/Troubleshoot'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button,Paper,Grid } from '@material-ui/core';
import '../../../stylesheet/error.css';
import MFASelfEnroll from '../Settings/mfaenrol';

const useStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: '40px',
      paddingRight: '40px',
      backgroundColor: '#F3F4F5'
    },
    toolbar: {
      marginTop: '30px',
      marginBottom: '30px',
    },
    paper: {
      boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
      border: 'none',
      marginLeft: '10px',
      marginRight: '10px',
      padding: '18px'
    },
    infoAttribute: {
      padding: '8px'
    }
  }));



function MfaEnrollMessage() {
    const handleChange = () => {
        window.open(config.mfa.selfEnrollInitUrl);
    };
    const classes = useStyles();
    return (
        <>
            {/* <FormControl fullWidth={props.fullWidth}> */}
                <Button style={{minWidth:'180px'}} color="primary" onClick={handleChange} variant="contained">
                Proceed For Self Enroll
                </Button>
            {/* </FormControl> */}
            <br/>
            <p>
                <br/>Please contact IT support at itsupport@olacabs.com (@OlaDesk) for any issues. <br/><a href="/home">Go To HomePage</a>
            </p>
        </>
    ); 
}

export default function MfaNotEnrolled(props) {
    // const {status, statusText,errorData, headers} = props.history.location.state
    const status = 400;
    const statusText = "";
    const errorData = {errorCode: "MFA005"};
    const headers = [];

    if(props.history.action === "POP")
        props.history.push(config.pageUri.error, {status: status, statusText: statusText, errorData:errorData,headers:headers})

    console.log("headers", headers)

    let title = "Oops...! Something went wrong"
    let message = ""
    let heading = "Error "+status;
    let icon = "/icons/error.svg"
    const errorCode = errorData ? errorData.errorCode : null;
    console.log("errorCode ",errorData);
    switch(status){
        case 400:
            if(errorCode === "MFA005"){
                icon = "/icons/authorized.svg"
                heading = "UNAUTHORIZED"
                title = "The application requires MFA enrollment"
                message = <MfaEnrollMessage/>
            }
            break
        case 401:
            title = "Unauthorized"
            if(errorCode === "AUTHN030"){
                title = "Link Expired"
            }
            break
        case 403:
            title = "Permission denied"
            break
        case 404:
            title = "Page not found"
            break
        case 429:
            title = "Retry limit exceeded. Please try after sometime"
            break
    }
    
    return (
        <div id="notfound">
            <div id="notfound">
                <div className="notfound">
                    <img id="error-icon" src={icon} style={{marginBottom: "20px"}}/>
                    <h2>{heading}</h2>
                    <p style={{fontWeight:'600'}}>{title}</p>
                    {/* <br/> */}
                    {/* <br/> */}
                    {message}
                    {/* {headers && 
                    <Troubleshoot 
                        body={
                            <div>
                            Transaction Id :
                            <p>"cidm-cli-ee7e0eef-99b3-495b-9b2e-2c49b206074a"</p>
                            Request Id :
                            <p>"cidm-cli-ee7e0eef-99b3-495b-9b2e-2c49b206074a"</p>
                            </div>
                        }
                    />} */}
                </div>
            </div>
        </div>
    )
}