import {
    GET_USER_ASSIGNED_APPLICATIONS,
    GET_USER_ASSIGNED_APPLICATIONS_FULFILLED,
    GET_USER_ASSIGNED_APPLICATIONS_REJECTED,

    GET_COMPLETE_USER_DETAILS,
    GET_COMPLETE_USER_DETAILS_FULFILLED,
    GET_COMPLETE_USER_DETAILS_REJECTED,

    GET_MFA_ENROLL_STATUS,
    GET_MFA_ENROLL_STATUS_FULFILLED,
    GET_MFA_ENROLL_STATUS_REJECTED,
    START_MFA_ENROLL,
    START_MFA_ENROLL_FULFILLED,
    START_MFA_ENROLL_REJECTED
} from '../constants/Action'

import {getUserSearchResourceGRPCClient, getTenantId} from './constants';
import {GetCompleteUserDetailsRequest} from '../proto/starlord/messages/request/get_complete_user_details_request_pb'
import {UserAssignedApplicationsRequest} from '../proto/starlord/messages/request/user_assigned_applications_request_pb'

import { MFA_GET_ENROL_STATUS_URL, MFA_SELF_ENROL_START_URL } from '../constants/Url';
import Axios from 'axios'
import config from '../config';

const UserService = getUserSearchResourceGRPCClient();

export function GetUserAssignedApplications(id){
    var request = new UserAssignedApplicationsRequest()
    request.setTenantid(getTenantId())
    request.setUserid(id)

    return dispatch => {
        dispatch({type: GET_USER_ASSIGNED_APPLICATIONS})
        UserService.getUserAssignedApplications(request, {'x-txn-id': sessionStorage.getItem("xTxnId")}, (err, response) => {
            if(err){
                dispatch({type: GET_USER_ASSIGNED_APPLICATIONS_REJECTED, payload: err})
            }
            else{
                dispatch({type: GET_USER_ASSIGNED_APPLICATIONS_FULFILLED, payload: response.toObject()})
            }
        })
    }
}

export function GetCompleteUserDetails(userId, getMfaEnrolStatusCB){
    var request = new GetCompleteUserDetailsRequest()
    request.setUserid(userId)
    return dispatch => {
        dispatch({type: GET_COMPLETE_USER_DETAILS})
        UserService.getCompleteUserDetails(request, {'x-txn-id': sessionStorage.getItem("xTxnId")}, (err, response) => {
            if(err){
                dispatch({type: GET_COMPLETE_USER_DETAILS_REJECTED, payload: err})
            }
            else{
                let data = response.toObject();
                getMfaEnrolStatusCB && dispatch(getMfaEnrolStatusCB(data.samaccountname, process.env.REACT_APP_TENANT_ID))
                dispatch({type: GET_COMPLETE_USER_DETAILS_FULFILLED, payload: data})

            }
        })
    }
}

export const getMfaEnrolStatus = (userId, tenantId)=>{

    let url = MFA_GET_ENROL_STATUS_URL + '?userId=' + userId + '&tenantId=' + tenantId
    return dispatch => { 
        if (!userId) {
            dispatch({type: GET_MFA_ENROLL_STATUS_REJECTED, payload: 3})
            return;
        }
        dispatch({type: GET_MFA_ENROLL_STATUS})
        Axios.get(url).then((response) => {
            if (response && response.status === 200 && response.data.mappingStatus === 'enrolled')
                dispatch({type: GET_MFA_ENROLL_STATUS_FULFILLED, payload: 1})
            else
                dispatch({type: GET_MFA_ENROLL_STATUS_FULFILLED, payload: 0})
        }).catch((error) => {
            dispatch({type: GET_MFA_ENROLL_STATUS_REJECTED, payload: 3})
        })
    }
}

export const startSelfMfaEnroll = (userId, tenantId, otpType)=>{
    let url = MFA_SELF_ENROL_START_URL + '?userId=' + userId;
    return dispatch => { dispatch({type: START_MFA_ENROLL})
        Axios.post(url, {
                "userDetails" : {
                "tenantId" : tenantId,
                "userId" : userId,
                "otpType" : otpType
            }

        }, {headers: {'x-integKey' : config.mfa.integKey}}).then((response) => {
            if (response.status == 200) {
              console.log(response.headers)
              dispatch({type: START_MFA_ENROLL_FULFILLED})
              window.location.replace(response.headers['x-location-id']);
            } else {
              dispatch({type: START_MFA_ENROLL_REJECTED, payload: -1})
            }
        }).catch((err) => {
            let errMsg;
             if ( err && err.response && err.response.data) {
                let data = err.response.data;
                if (data.errorCode.startsWith('RTLMTD')) {
                    errMsg = 'OTP limit exceeded.'
                } else {
                    errMsg = data.message;
                }
            } else {
              errMsg = 'Something went wrong.'
            }
            dispatch({type: START_MFA_ENROLL_REJECTED, payload: errMsg})
        })
    }
}
