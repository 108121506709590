import axios from 'axios'
import { getFingerprint } from './fingerprint'
import { v4 as uuidv4 } from 'uuid';
import { retrieveUri } from './utils';
import appConfig from "../config/index";

let mockingEnabled = false

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY


const mocks = {"http://localhost:7001/api/v1/audits": {data: {auditList: [{id: "test", name: "test", schedulePeriod: 2, schedulePeriodUnit: "HOUR"}]}}}

const loadScriptByURL = (id, url, callback) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
}

export function addMock(url, data) {
  mocks[url] = data
}

export function enableMocking(state) {
  mockingEnabled = state
}

const isUrlMocked = url => url in mocks

const getMockError = config => {
    const mockError = new Error()
    mockError.mockData = mocks[config.url]
    mockError.config = config
    return Promise.reject(mockError)
  }
  
  const isMockError = error => Boolean(error.mockData)
  
  const getMockResponse = mockError => {
      console.log("getting mock response...", mockError)
    const {mockData, config} = mockError
    // Handle mocked error (any non-2xx status code)
    // if (mockData.status && String(mockData.status)[0] !== '2') {
    //   const err = new Error(mockData.message || 'mock error')
    //   err.code = mockData.status
    //   return Promise.reject(err)
    // }
    // Handle mocked success
    return Promise.resolve(Object.assign({
      data: mocks[config.url],
      status: 200,
      statusText: 'OK',
      headers: {},
      config,
      isMock: true
    }, mockData))
  }

  // Add a request interceptor
  axios.interceptors.request.use(config => {
    // console.log('axios mocking ...' + config.url);

    if (mockingEnabled && isUrlMocked(config.url)) {
      console.log('axios mocking ' + config.url)
      return getMockError(config)
    }
    
    config.headers['x-txn-id'] = "cidm-cli-" + uuidv4();
    config.withCredentials = true;

    // return config;
    
    return getFingerprint().then(res => {
      // sessionStorage.setItem('xFpId', res);
      config.headers['x-fp-id']  = res;
      // return Promise.resolve(config);
      if(!appConfig.recaptch.enabledApiPaths.includes(retrieveUri(config.url)))
        return Promise.resolve(config);
      return new Promise((resolve, reject) => {
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
              console.log("token..", token);
              config.headers["_captcha_token"] = token;
              resolve(config);
            });
          });
        });
      })
    })
    }, error => Promise.reject(error))
  
  // Add a response interceptor
  axios.interceptors.response.use(response => response, error => {
      console.log("error...", error)
    if (isMockError(error)) {
      return getMockResponse(error)
    }
    return Promise.reject(error)
  })