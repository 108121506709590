import React, {useEffect, useState} from 'react'
import config from '../../config';
import OlaLoader from '../Loader';
import Axios from 'axios';
import { useHistory, useParams } from 'react-router';
import { retrieveUri } from '../../helpers/utils';

export default function AppLogin(props){

    const [loading, setLoading] = useState(true)
    const history = useHistory()
    let { appId, tenantId } = useParams()

    useEffect(()=> {
        Axios.get(config.server.basePath + config.server.serviceProviderLoginPath.replace("{tenant_id}", tenantId).replace("{app_id}", appId), 
          {
            params: {
              origin: 'home'
            }
          })
        .then((response)=>{
            setLoading(false)
        if(response.status === 200){
          if(response.headers["content-type"].includes("text/html")){
            history.push(config.pageUri.confirmationForm, {data: response.data})
          }
          else if(response.data.is_logged_in){
            history.push(config.pageUri.home, {authenticated: true})
          }
          else if(!response.data.is_logged_in){
            history.push(retrieveUri(response.data.policy_page_url),{additionalInfo: response.data.additional_info});
          }
        }
    }).catch((error)=>{
      setLoading(false)
      console.log("applogin ",error.response);
      history.push(config.pageUri.error, {status: error.response.status, statusText: error.response.statusText, headers: error.response.headers, errorData : error.response.data})
    })
    }, [])
    
    if(loading)
        return (
          <div style={{marginTop: "300px"}}>
            <OlaLoader />
            <div className="text-center">
              Signing In...
            </div>
          </div>
        )
    return <></>
}