import {createStore, applyMiddleware} from 'redux';
import reducer from "../reducers";
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import {persistReducer,persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
  
const persistConfig = {
  key: 'root',
  storage
}


const middleware = applyMiddleware(thunk,logger)

const persistedReducer = persistReducer(persistConfig, reducer)

let store = createStore(persistedReducer,middleware)

export default() => {
  let persistor = persistStore(store)
  return {store, persistor}
}