import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  alert: {
    boxShadow: 'none',
    border: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.36)',
    color: 'black'
  },
  alertTitle: {
    fontWeight: '600'
  }
}));

export default function NoRecords(props) {
  const classes = useStyles();

  return (
    <Alert className={classes.alert} severity="info" iconMapping={{ info: <img src="/icons/search-error.svg" style={{marginBottom: '24px'}}/> }}>
      <AlertTitle className={classes.alertTitle}>{props.title}</AlertTitle>
      {props.description}
    </Alert>
  );
}