import './polyfills';

import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'

import './es6-polyfills'

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './javascript/App';
import configStore from './javascript/store'
import {Provider} from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './javascript/theme';
import log from 'loglevel';
import { PersistGate } from 'redux-persist/integration/react';


log.enableAll();
const {persistor, store} = configStore();

// disabled logs in production env

function noop() {}

if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

ReactDOM.render(<MuiThemeProvider theme={theme}>
                    <Provider store={store}>
                      <PersistGate loading={null} persistor={persistor}>
                        <App />
                      </PersistGate>
                    </Provider>
                </MuiThemeProvider>, document.getElementById('root'));
