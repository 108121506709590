import React, {useEffect, useState} from "react";
import { HOME_LOGIN_URL } from "../constants/Url";
import { useSelector, useDispatch } from 'react-redux'
import { RESET_ERROR, AUTHENTICATION_SUCCESS, AUTHENTICATION_FAILED, RESET_POLICY_EXECUTION_ENDPOINT, POLICY_EXECUTION_SUCCESS } from "../constants/Action";
import { useHistory } from "react-router-dom";
import {isCompleteUri, retrieveUri} from './utils'
import OlaLoader from "../components/Loader"
import config from "../config";
import Axios from "axios";
import log from 'loglevel'

const withHomeAuth = Component => props => {
    const [loading, setLoading] = useState(true);
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const history = useHistory();


    useEffect(() => {
        dispatch({ type: RESET_ERROR })
        dispatch({ type: RESET_POLICY_EXECUTION_ENDPOINT })
        const login = async() => {
            await Axios.post(HOME_LOGIN_URL, "").then((response)=>{
                if(response.status === 200){
                    if(response.data.is_logged_in){
                        dispatch({ type: AUTHENTICATION_SUCCESS })
                        // dispatch({ type: RESET_POLICY_EXECUTION_ENDPOINT })
                        sessionStorage.setItem("login_id", response.headers["x-login-id"])
                        sessionStorage.setItem("internal_id", response.headers["x-internal-id"])
                        setLoading(false);
                    }
                    else if(!response.data.is_logged_in){
                        // dispatch({ type: POLICY_EXECUTION_SUCCESS, payload: response.data})
                        history.push(retrieveUri(response.data.policy_page_url), {additionalInfo: response.data.additional_info});
                    }
                }
            }).catch((error)=>{
                log.debug("Policy Authn error", error);
                setLoading(false);
                dispatch({ type: AUTHENTICATION_FAILED, payload: error })
                history.push(config.pageUri.error, {status: error.response.status, statusText: error.response.statusText, headers: error.response.headers, errorData : error.response.data})
            })
        }
        if(!history.location.state || !history.location.state.authenticated)
            login();
        else{
            setLoading(false);
            window.history.pushState(null, null, window.location.href);
        }
    }, []);

    return loading? <div style={{marginTop: '300px'}}><OlaLoader/></div> : auth.authenticated?<Component {...props } />:<></>;
};

export default withHomeAuth;