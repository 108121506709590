import React, { useEffect, useState } from 'react'
import "../../../stylesheet/app-card.css"

export default function MyApp(props){
    const [description, setDescription] = useState("")
    const [bigName, setBigName] = useState(false)
    const [bigNameValue, setBigNameValue] = useState("")
    const [imageData, setImageData] = useState(localStorage.getItem(props.logourl));
    
    useEffect(()=> {
        const maxSize = 70
        if(props.description.length > maxSize){
            setDescription(props.description.substring(0, maxSize) + "....")
        }
        else
            setDescription(props.description)
    }, [props.description])

    useEffect(()=> {
        const maxSize = 22
        if(props.name.length > maxSize){
            setBigName(true)
            setBigNameValue(props.name.substring(0, 22) + "....")
        }
    }, [props.name])

    return (
        <a className={props.category === 1?"card4 my-app":"card4 other-app"} href={props.href} target="_blank">
            {props.logourl ? <img src={props.logourl}/>:<img src="/icons/generic-app.svg"/>}
            <div className={bigName?"text-parent big-name":"text-parent"}>
                <div className="name-parent">
                    {bigName?<h6>{bigNameValue}</h6>:<h6>{props.name}</h6>}
                </div>
                {props.category === 2 &&
                <div className="description-parent">
                    <p className="small">{description}</p>
                </div>}
            </div>
        </a>
    )
}