import {createMuiTheme} from '@material-ui/core'

const theme = createMuiTheme({
    palette: {
        primary: {
          main: '#000',
          light: '#F3F4F5'
        },
        secondary: {
          main: '#16AA51',
        },
    },
    typography: {
        fontFamily: [
          'Gentona'
        ].join(','),
      },
    props: {
        MuiButton: {
            disableElevation: true,
            disableRipple: true
        },
        MuiTextField: {
            color:'secondary',
            variant: 'standard',
            fullWidth: true
        },
        MuiInputLabel: {
            variant: 'filled',
            required: false
        },
        MuiInput: {
            disableUnderline: true
        },
        MuiPaper: {
            variant: 'outlined',
            square: true,
        }
    },
    overrides: {
        MuiTab: {
            root: {
                "&.MuiButtonBase-root": {
                    fontWeight: 600
                },
                "&.MuiButtonBase-root.Mui-selected": {
                    fontWeight: 700
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: 0,
                height: '48px',
                marginTop: '8px',
                marginBottom: '8px',
                fontWeight: '450',
                "&.MuiButton-containedPrimary": {
                    background: 'linear-gradient(180deg, #2E2E2E 0%, #121212 100%)',
                },
                "&.MuiButton-containedPrimary:hover": {
                    background: 'linear-gradient(180deg, #3F3A3A 0%, #4F4A4A 100%)',
                    color: '#fff',
                }
            },
            label: {
                fontSize: '16px',
                textTransform: 'none'
            }
        },
        MuiTextField: {
            root: {
                marginTop: '6px',
                marginBottom: '6px',
                height: '70px'
            }
        },
        MuiInput: {
            root: {
                color: '#000',
                borderRadius: 0,
                border: '1px solid rgba(0, 0, 0, 0.12)',
                padding: '20px 12px 4px 12px',
                caretColor: '#16AA51',
                "&.Mui-focused": {
                    backgroundColor: "#F3F4F5",
                    border: '2px solid #16AA51'
                },
                "&.Mui-disabled": {
                    backgroundColor: "#F3F4F5",
                    border: 'none'
                },
                "&.Mui-error": {
                    border: '2px solid #C91A3A',
                    caretColor: '#C91A3A'
                },
                "&:input": {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiFormHelperText: {
            root: {
                fontWeight: '600',
                "&.Mui-error": {
                    color: "#C91A3A"
                }
            }
        },
        MuiInputLabel: {
            root: {
                color: 'rgba(0, 0, 0, 0.36)',
                marginTop: '16px',
                caretColor: '#16AA51',
                "&.Mui-error": {
                    color: "#C91A3A",
                    caretColor: '#C91A3A'
                }
            },
            shrink: {
                fontWeight: 'bold'
            }
        },
        MuiInputAdornment: {
            root: {
                marginBottom: '14px',
                color: 'black'
            }
        },
        MuiPaper: {
            root: {
                padding: "16px",
                boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.24), 0px 1px 3px rgba(0, 0, 0, 0.12)"
            }
        },
        MuiSnackbar: {
            root: {
                borderRadius: 0,
                backgroundColor: 'black'
            }
        }
    }
});

export default theme;