import React, { useEffect, useState } from 'react';
import {TextField} from '@material-ui/core'
import LoginContainer from '../Layout/LoginContainer'
import LoginButton from '../Layout/LoginButton';
import { useDispatch } from 'react-redux';
import { SET_EMAIL } from '../../constants/Action';
import { FORM_ERROR, OTHER_ERROR } from '../../constants/Error';
import {Chip, Typography, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import { SET_DIRECTORY } from '../../constants/Action';
import { directoriesConfig } from '../../constants/Form';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import config from '../../config';
import log from 'loglevel';


const useStyles = makeStyles((theme) => ({
    chipContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  }));

export default function UsernameVerification(props) {
    const classes = useStyles();

    const [username, setUsername] = useState("");
    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth)
    const selectedDirectoryId = authState.directoryId

    log.debug("selectedDirectoryId", selectedDirectoryId)

    useEffect(()=> {
        console.log("selectedDirectoryId", authState)
        if(selectedDirectoryId == '' || selectedDirectoryId == undefined){
            dispatch({type:SET_DIRECTORY,payload:{directoryId: config.directory.defaultDirectoryId}});
        }
    }, [selectedDirectoryId])
    
    const handleDirectoryChange = (e) => {
        dispatch({type:SET_DIRECTORY,payload:{directoryId: e.target.value}});
    }

    const onSubmit = (event)=>  {
        event.preventDefault();
        props.handlePolicySubmit({
            username : username
        })
        dispatch({type: SET_EMAIL, payload: username})
    }

    const onUsernameChange = (event)=>{
        setUsername(event.target.value)
    }

    return (
        <div className="login-sm-container">
            <LoginContainer 
                title="Sign in with credentials"
                onSubmit={onSubmit} 
                loading={props.loading}
                action="Next"
                errorState={props.errorState && props.errorType === OTHER_ERROR}
                errorCode={props.errorCode}
                errorMessage={props.errorMessage}
            >
                {!(config.directory.useDefault == "true") && 
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Directory</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedDirectoryId}
                            label="directory"
                            onChange={handleDirectoryChange}
                            >
                            {directoriesConfig.map(d => (<MenuItem key={d.id} value={d.id} selected={d.id === selectedDirectoryId}>{d.displayName}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                }
                {/* <div>
                    {directoriesConfig.map(d => (<Chip
                      key={d.id}
                      label={d.displayName} value={d.id}
                      variant={d.id === selectedDirectoryId? "default":"outlined"}
                      onClick={() => {handleDirectoryChange(d)}}
                      style={{margin: '5px'}}
                    />
                    ))}
                </div> */}
                <TextField
                    name="username"
                    icon="user"
                    label="Username"
                    autoComplete="username"
                    required 
                    type='text'
                    error = {props.errorState && props.errorType === FORM_ERROR}
                    helperText = {props.errorState && props.errorType === FORM_ERROR?props.errorMessage:""}
                    value = {username} onChange = {onUsernameChange}
                    disabled={props.loading}
                    inputRef={input => input && input.focus()}
                />
                <LoginButton onSubmit={onSubmit} loading={props.loading}>Next</LoginButton>
            </LoginContainer>
        </div>
    )
}

