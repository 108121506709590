export const directoriesConfig= [
    {
        id: "3338f19a-c0bb-43dd-8e75-1f07289b1a77",
        displayName: "Ola Corp"
    },
    {
        id: "03d300d2-c43e-4eef-aa34-080d9b163fd9",
        displayName: "Ola Corp Test",
    },
    {
        id: "e03ed067-1af7-498a-a939-8d10aff3aa34",
        displayName: "Ola Plant"
    }
]